import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { L } from 'harmony-language';
import { PhoneNumber } from '../shared/phone-number';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { ResourceConflictTooltip } from '../shared/containers/resource-conflict-tooltip';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import { DriverDefaults } from './driver-defaults';

const Actions = styled(CardActions)(({ theme }) => ({
    backgroundColor: theme.palette.grey['100'],
    justifyContent: 'flex-end'
}));

const NameDiv = styled('div', {
    shouldForwardProp: (prop) => prop !== 'hasConflict',
})(({ hasConflict, theme }) => ({
    display: 'flex',
    ...(hasConflict && {
        color: theme.palette.error.main
    })
}));

export const DriverAssignmentCard = (props) => {
    const { assignDriver, driver, driverConflicts } = props;
    const [showConfirmation, setShowConfirmation] = React.useState(false);

    const renderedDriverConflicts = React.useMemo(() => {
        const driverConflict = driverConflicts.find(x => x.driverId === driver.id);

        const conflicts = [
            ...driverConflict.downtimeConflicts,
            ...driverConflict.bookingConflicts,
        ]

        const renderedConflicts = conflicts.filter(Boolean).reduce((acc, current) => {
            return acc === null ? current : <React.Fragment>{acc}
                <hr style={{ border: 0, borderTop: '1px solid white'}} />
                {current}</React.Fragment>;
        }, null);

        return renderedConflicts;
    }, []);

    const hasConflict = React.useMemo(() => {
        const driverConflict = driverConflicts.find(x => x.driverId === driver.id);
        const downtimeLength = driverConflict.downtimeConflicts.length;
        const bookingLength = driverConflict.bookingConflicts.length;

        if (downtimeLength || bookingLength) {
            return true
        } else {
            return false
        }
    }, [driverConflicts]);

    return (
        <Card style={{ width: '100%' }}>
            <CardContent>
                {showConfirmation ? (
                    <Typography variant='h6'>{L.assignSure(driver.name)}</Typography>
                ) : (
                        <>
                            <NameDiv hasConflict={hasConflict}>
                                <Typography variant='h6'>{driver.name}</Typography>
                                {hasConflict && <ResourceConflictTooltip tooltipComponent={<div>{renderedDriverConflicts}</div>}>
                                    <WarningIcon
                                        style={{ fontSize: '1em', height: 'auto' }} />
                                </ResourceConflictTooltip>}
                            </NameDiv>
                            <Typography color='textSecondary'>
                                <PhoneNumber className='driver-phone' phone={driver.phone} />
                            </Typography>
                            <Typography color='textSecondary'>
                                {driver?.driverInfo ? <DriverDefaults driverInfo={driver.driverInfo} /> : null}
                            </Typography>
                        </>
                )}
            </CardContent>
            <Actions>
                {!showConfirmation &&
                    <Button variant='text' color='primary' onClick={() => setShowConfirmation(true)}>{L.assign()}</Button>}
                {showConfirmation && <Button variant='text' color='primary'
                    onClick={() => setShowConfirmation(false)}>{L.assignNo()}</Button>}
                {showConfirmation &&
                    <Button variant='text' color='primary' onClick={() => assignDriver(driver)}>{L.assignYes()}</Button>}
            </Actions>
        </Card>
    );
};

DriverAssignmentCard.propTypes = {
    assignDriver: PropTypes.func,
    driver: PropTypes.object,
    driverConflicts: PropTypes.array,
};
