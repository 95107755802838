import { Load, OrganizationLocation, Stop } from '../types';

// SiteDescription (SiteName) - SublocationName
export const toSiteDisplayName = (siteName: string, siteDescription: string | null, sublocationNames?: string | null) => {
    const description = siteDescription ? siteDescription : '';
    return (`${description} (${siteName})${sublocationNames ? ` - ${sublocationNames}` : ''}`).trim();
};

export const toSiteDisplayNameFromOrganizationLocation = (organizationLocation: OrganizationLocation) => {
    return toSiteDisplayName(organizationLocation.name, organizationLocation.description);
}

// copy of version from use-location-info
export const toSublocationDisplay = (stopsWithSublocations: Stop[], organizationLocation: OrganizationLocation) => {
    if (!stopsWithSublocations.length) {
        return null;
    }

    const uniqueSublocationIds = [...new Set(stopsWithSublocations.flatMap(x => x.organizationSubLocationIds))];
    const sublocations = organizationLocation.orgSubLocations.filter(sub => uniqueSublocationIds.includes(sub.id)).sortBy(s => s.name) || [];
    return sublocations.map(s => s.name).join(' - ');
}

export const loadToOrganizationLocationIdPairs = (load: Load) => {
    const organizationLocationIdsList = load.stops?.sortBy((stop) => stop.sequence).map(stop => stop.organizationLocationId || 0) || [];

    const pairList: number[][] = [];

    for (let i = 0; i < organizationLocationIdsList.length - 1; i++) {
        if (organizationLocationIdsList[i] && organizationLocationIdsList[i + 1] && organizationLocationIdsList[i] > 0 && organizationLocationIdsList[i + 1] > 0) {
            pairList.push([organizationLocationIdsList[i], organizationLocationIdsList[i + 1]]);
        }
    }

    return pairList;
};

export const loadsToOrganizationLocationIdPairs = (loads: Load[]) => {
    const arrayOfOrganizationLocationIds = loads.map(load => {
        const sortedStops = load.stops?.sortBy(stop => stop.sequence);
        const orgLocationIds = sortedStops.map(sortedStop => sortedStop.organizationLocationId || 0);
        return orgLocationIds;
    }) || [];

    const pairList: number[][] = [];

    arrayOfOrganizationLocationIds.forEach(organizationLocationIds => {
        for (let i = 0; i < organizationLocationIds.length - 1; i++) {
            pairList.push([organizationLocationIds[i], organizationLocationIds[i + 1]]);
        }
    });

    return pairList;
};

export const orgSubLocationToItems = (locations: OrganizationLocation[], organizationLocationId: number | null | undefined) => {
    const currentLoc = locations.find(l => l.id === organizationLocationId);
    return currentLoc ? currentLoc.orgSubLocations.map(x => ({ id: x.id, value: x.name, })) : [];
}
