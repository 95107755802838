import React from 'react';
import { Load } from '../../../types';
import { L } from 'harmony-language';
import { LoadTable2StopRow } from './load-table2-stop-row';
import { Button } from '@mui/material';
import { useDraftLoadContext } from '../hooks/draft-load-context';
import { Save } from '@mui/icons-material';
import { stopGridTemplateColumnsGenerator } from '../utils';

interface LoadTable2StopsProps {
    isDrafts: boolean;
    // load: Load;
}

export const LoadTable2Stops: React.FC<LoadTable2StopsProps> = (props) => {
    const { isDrafts } = props;
    const { editedLoad, isDirty, saveEditedLoad } = useDraftLoadContext();

    return (
        <div className='lt-stops-container'>
            <div
                className='lt-row-stop lt-row-stop-header'
                style={{ gridTemplateColumns: stopGridTemplateColumnsGenerator(isDrafts) }}
            >
                <div>{L.stopType()}</div>
                <div>{L.location()}</div>
                <div>{L.subLocations()}</div>

                <div>{L.readyTime()}</div>
                <div>{L.scheduled()}</div>
                {isDrafts ? null : <div>{L.completed()}</div>}

                {isDrafts ? <div>{L.quantity()}</div> : <div>{L.planned()}</div>}
                {isDrafts ? null : <div>{L.actual()}</div>}

                {isDrafts ? <div>{L.weight()}</div> : <div>{L.planned()}</div>}
                {isDrafts ? null : <div>{L.actual()}</div>}

                <div>{L.cargoType()}</div>

                <div>{L.orderNumber()}</div>
                <div>{L.productionPlan()}</div>
                <div>{L.pickUp()} #</div>
                <div>{L.notes()}</div>
                <div />
                <div />
            </div>
            {editedLoad.stops.map(stop => {
                return (
                    <LoadTable2StopRow
                        key={stop.id}
                        isDrafts={isDrafts}
                        stop={stop}
                        trailerId={editedLoad.trailerId}
                    />
                )
            })}
            <div
                className='lt-row-stop-item'
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    // marginTop: '1rem',
                    padding: '.5rem 1rem .5rem 0',
                }}
            >
                <Button
                    variant='contained'
                    disabled={!isDirty}
                    startIcon={<Save />}
                    size='small'
                    onClick={() => {
                        saveEditedLoad();
                    }}
                >
                    {L.save()}
                </Button>
            </div>
        </div>
    );
};
