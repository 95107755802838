import React, { FC } from 'react';

interface LoadOrderNumbers2Props {
    id: number;
    orderNumbers: string[];
}

export const LoadOrderNumbers2: FC<LoadOrderNumbers2Props> = (props) => {
    const { id, orderNumbers } = props;

    if (orderNumbers.length) {
        const orderNumbersDisplay = orderNumbers.join(', ');
        const title = `${id.toString()} - ${orderNumbersDisplay}`;

        return (
            <div
                className='lt-cell-sub'
                title={title}
            >
                {orderNumbersDisplay}
            </div>
        );
    } else {
        return <div>{id}</div>;
    }
}
