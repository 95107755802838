import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { mutationOptions } from '../config';
import { useSnackbar } from '../../components/notifications/use-snackbar';
import { Load } from '../../types';
import { useDraftsKey } from '../config-hooks';

async function modifyDrafts(options: { drafts: Load[] }) {
    const drafts = options.drafts;

    return (await axios.put(`${API_BASE}/api/orders`, drafts)).data;
}

export const useDraftsMutation = () => {
    const snackbar = useSnackbar();
    const draftsKey = useDraftsKey();
    const queryClient = useQueryClient();

    return useMutation(modifyDrafts, {
        ...mutationOptions(draftsKey, queryClient),
        onMutate: ({ drafts }: { drafts: Load[] }) => {
            queryClient.cancelQueries(draftsKey);

            const previousCache = queryClient.getQueryData<Load[]>(draftsKey);

            if (previousCache) {
                const draftIds = drafts.map(d => d.id);
                const newDrafts = previousCache.map((existingDraft) => {
                    if (draftIds.includes(existingDraft.id)) {
                        return {
                            ...existingDraft,
                            ...drafts.find(d => d.id === existingDraft.id),
                        };
                    }
                    return existingDraft;
                });

                // Optimistically update to the new value
                queryClient.setQueryData(draftsKey, newDrafts);
            }

            // Return a rollback function
            return () => queryClient.setQueryData(draftsKey, previousCache);
        },
        onSuccess: (data) => {
            snackbar(L.draftsUpdateSuccessful(data.length));
        },
        onSettled: () => {}
    });
};
