import { flexRender, Table } from '@tanstack/react-table';
import React from 'react';
import { Load } from '../../../types';
import { STATUS_COLUMN } from '../use-loads2-columns';

export const gridTemplateColumnsGenerator = (isDrafts: boolean, showEstimatedTotalTripTime: boolean) => {
    const gridTemplateColumns = [
        ...(isDrafts ? ['100px'] : ['80px']),
        '1fr',
        '1.5fr',
        '1.5fr',
        '1fr',
        '1fr',
        ...(isDrafts ? [] : ['1fr']),
        ...(showEstimatedTotalTripTime ? ['.5fr'] : []),
        '1fr',
        '1fr',
        '1fr',
        '1fr',
        '.5fr',
        '40px',
    ];

    return gridTemplateColumns.join(' ');
}

interface LoadTable2HeaderProps {
    isDrafts: boolean;
    table: Table<Load>;
    showEstimatedTotalTripTime: boolean;
}

export const LoadTable2Header: React.FC<LoadTable2HeaderProps> = (props) => {
    const { isDrafts, table, showEstimatedTotalTripTime } = props;

    return (
        <div className='lt-header'>
            {table.getHeaderGroups().map(headerGroup => (
                <div
                    key={headerGroup.id}
                    className='lt-row'
                    style={{ gridTemplateColumns: gridTemplateColumnsGenerator(isDrafts, showEstimatedTotalTripTime) }}
                >
                    {headerGroup.headers.map(header => {
                        const isStatusColumn = header.id === STATUS_COLUMN;

                        return (
                            <div
                                key={header.id}
                                style={isStatusColumn ? { gridColumn: 'span 2' } : undefined}
                                className='lt-header-item'
                            >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};
