import { Autocomplete, AutocompleteRenderGetTagProps, TextField } from '@mui/material';
import React from 'react';
import { CustomPopper } from './multi-select/custom-popper';

export interface AgisticsItem {
    id: number;
    value: string;
}

interface AgisticsSelectProps {
    item: number | null;
    items: AgisticsItem[];
    onChange: (n: number) => void;
    label?: string;
    disabled?: boolean;
}

// this is very incomplete, just needed something quick for standard route selects
export const AgisticsSelect: React.FC<AgisticsSelectProps> = (props) => {
    const { item, items, onChange, label, disabled = false } = props;
    const selectedValue = React.useMemo(() => items.find(x => x.id === item), [item]);

    return (
        <Autocomplete
            // multiple
            // disableCloseOnSelect
            // disableClearable={confinedSpace}
            disableClearable={true}
            disabled={disabled}
            options={items}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            // value={selectedValues}
            value={selectedValue}
            onChange={(event, value) => {
                if (event.type !== 'keydown' && value) {
                    onChange(value.id);
                    // const mappedVal = value.map(x => x.id);
                    // setSelectedIds(mappedVal);
                    // presistPrefsChange && presistPrefsChange(mappedVal);
                }
            }}
            // PopperComponent={confinedSpace ? CustomPopper : undefined}
            PopperComponent={CustomPopper}
            // sx={confinedSpace ? { input: { fontSize: '.875rem' }} : undefined}
            renderInput={(params) => {
                const newParams = {
                    ...params,
                    inputProps: {
                        ...params.inputProps,
                        style: { fontSize: '14px' },
                    }
                }

                return <>
                    <TextField
                        // inputProps={{ fontSize: '14px' }}
                        // inputProps={{ style: { fontSize: '14px' }}}
                        // {...params}
                        {...newParams}
                        variant='standard'
                        label={label}
                    />
                </>
            }}
            // renderTags={renderSelected}
            renderOption={(props, option) => {
                return (
                    <li
                        {...props}
                        // props passes a key that is value, which might not be unique (users/contacts with same name)
                        key={option.id}
                    >
                        {/* <Checkbox checked={selectedIds.indexOf(option.id) > -1} style={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }} /> */}
                        {option.value}
                    </li>
                )
            }}
        />
    );
};
