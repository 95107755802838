import { Button } from '@mui/material';
import React, { useMemo } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh';
import Merge from '@mui/icons-material/MergeType';
import Delete from '@mui/icons-material/Delete';
import ClearAll from '@mui/icons-material/ClearAll';
import Send from '@mui/icons-material/Send';
import AssignmentReturned from '@mui/icons-material/AssignmentReturned';
import { L } from 'harmony-language';
import { downloadDeliveryTickets, downloadLoadsCSV } from '../../utils/download-utils';
import { Table } from '@tanstack/react-table';
import { Load } from '../../types';
import { useCompanyId } from '../../app/agistics-context';
import { useWeights } from '../shared/hooks/use-weights';
import { useCarrier, useFeatures, usePermissions } from '../user/selectors/use-permissions';
import { Features, OrderStatus, PermissionType } from 'harmony-constants';
import { ordersToCommonStopLocationIds } from '../../utils/data-mapping-utils';
import { isDraftValid } from '../drafts/utils/validate-drafts';
import { useCarriers } from '../../api/queries/use-carriers';

// OLD NOTES
// calling getSelectedRowModel while using <- -> buttons causes infinite loop.
// unsure if this is because something getSelectedRowModel is doing or something not wired up in react-query properly
// still not sure what causes this, but this still needs to be memoized...
// const selectedLoads = useMemo(() => {
//     console.log('useMemo!');
//     return table.getSelectedRowModel().rows.map(x => x.original);
// }, [table.getState().rowSelection, table]);

// after not touching this for 2 weeks, it no longer needs to be useMemo I guess
// if it is memo and a load is invalid to be published, you edit it so it is valid
// it will still disable to publish button...
// still infinite loop when changing from drafts -> loads if not useMemo

// NEW NOTES
// calling getSelectedRowModel() while loading new (loads/drafts) data seams to be what causes the infinite loop
// just doing an empty return while loading fixes it.  We are resetting selection state and not showing this component
// so will not affect visibility / usability anyways.
// a reason we can't memo it:
//      On drafts - Have 1+ rows selected. Edit it so it is no longer in a 'valid for publish' state.
//      Previously it would have shown that load as 'valid for publish' as getSelectedRowModel() was memoized

interface SelectionButtonsProps {
    isDrafts: boolean;
    isLoading: boolean;
    table: Table<Load>;
    setCompletableLoads: (l: Load[]) => void;
    setAssignableLoads: (l: Load[]) => void;
    setCarrierLoads: (l: Load[]) => void;
    setDeletableLoads: (l: Load[]) => void;
    setBulkModifyRecords: (l: Load[]) => void;
    setMergeRecords: (l: Load[]) => void;
    setCascadeRecords: (l: Load[]) => void;
    setPublishRecords: (l: Load[]) => void;
}

export const SelectionButtons: React.FC<SelectionButtonsProps> = (props) => {
    const { isDrafts, isLoading, table, setCompletableLoads, setAssignableLoads, setCarrierLoads, setDeletableLoads, setBulkModifyRecords, setMergeRecords, setCascadeRecords, setPublishRecords } = props;
    const companyId = useCompanyId();
    const { weightSystem, convertFromGrams } = useWeights();
    const hasDeliveryTicketsFeature = useFeatures(Features.DeliveryTickets);
    const hasMergeDraftsFeature = useFeatures(Features.MergeDrafts);
    const hasCascadeFeature = useFeatures(Features.CascadingStopScheduling)

    const canCompleteOrder = usePermissions(PermissionType.CompleteOrder);
    const canAssignDrivers = usePermissions(PermissionType.AssignDrivers);
    const canDeleteOrders = usePermissions(PermissionType.DeleteOrder);

    const currentUserIsCarrierOrg = useCarrier();
    const { carriers } = useCarriers();

    // read above for more info on this isLoading check
    if (isLoading) {
        return <></>;
    }

    const selectedLoads = table.getSelectedRowModel().rows.map(x => x.original);

    const isDisabled = !Boolean(selectedLoads.length);

    const anySelectedLoadsDelivered = selectedLoads.some(x => x.status === OrderStatus.Delivered);
    const allSelectedOpenOrAssigned = selectedLoads.every(x => x.status === OrderStatus.Open || x.status === OrderStatus.Assigned);
    const sameOrg = selectedLoads.every(x => x.transportingOrganizationId === companyId);

    return (
        Boolean(selectedLoads.length) && <div className='lt-selection-buttons'>
            {isDrafts && <Button
                variant='contained'
                startIcon={<AutoFixHigh />}
                onClick={() => {
                    setBulkModifyRecords(selectedLoads);
                }}
                disabled={isDisabled}
            >
                {L.bulkModify()}
            </Button>}
            {isDrafts && hasMergeDraftsFeature && <Button
                variant='contained'
                startIcon={<Merge style={{ transform: 'rotate(90deg)' }} />}
                onClick={() => {
                    setMergeRecords(selectedLoads);
                }}
                disabled={isDisabled || selectedLoads.length <= 1}
            >
                {L.merge()}
            </Button>}
            {isDrafts && hasCascadeFeature && <Button
                variant='contained'
                startIcon={<ClearAll style={{ transform: 'scaleX(-1)' }} />}
                onClick={() => {
                    setCascadeRecords(selectedLoads);
                }}
                disabled={!Boolean(selectedLoads.length > 1 && ordersToCommonStopLocationIds(selectedLoads).length)}
            >
                {L.cascade()}
            </Button>}
            {isDrafts && <Button
                variant='contained'
                startIcon={<Send />}
                onClick={() => {
                    setPublishRecords(selectedLoads);
                }}
                disabled={isDisabled || !selectedLoads.every(x => isDraftValid(x, convertFromGrams))}
            >
                {L.publish()}
            </Button>}
            {!isDrafts && <Button
                variant='contained'
                startIcon={<CloudDownloadIcon />}
                onClick={() => {
                    downloadLoadsCSV(companyId, weightSystem, selectedLoads.map(x => x.id));
                }}
                disabled={isDisabled}
            >
                {L.exportData()}
            </Button>}
            {!isDrafts && hasDeliveryTicketsFeature && <Button
                variant='contained'
                startIcon={<PictureAsPdfIcon />}
                onClick={() => {
                    downloadDeliveryTickets(selectedLoads.map(x => x.id));
                }}
                disabled={isDisabled}
            >
                {L.deliveryTicket()}
            </Button>}
            {!isDrafts && !currentUserIsCarrierOrg && canCompleteOrder && <Button
                variant='contained'
                startIcon={<AssignmentTurnedIn />}
                onClick={() => {
                    setCompletableLoads(selectedLoads);
                }}
                disabled={isDisabled || anySelectedLoadsDelivered}
            >
                {L.manuallyCompleteLoad()}
            </Button>}
            {!isDrafts && canAssignDrivers && <Button
                variant='contained'
                startIcon={<AssignmentIndIcon />}
                onClick={() => {
                    setAssignableLoads(selectedLoads);
                }}
                disabled={isDisabled || anySelectedLoadsDelivered || !sameOrg}
            >
                {L.assignDriver()}
            </Button>}
            {!isDrafts && !currentUserIsCarrierOrg && carriers && carriers.length > 0 && <Button
                variant='contained'
                startIcon={<AssignmentReturned />}
                onClick={() => {
                    setCarrierLoads(selectedLoads);
                }}
                disabled={isDisabled || anySelectedLoadsDelivered}
            >
                {L.assignCarrier()}
            </Button>}
            {!currentUserIsCarrierOrg && canDeleteOrders && <Button
                variant='contained'
                startIcon={<Delete />}
                onClick={() => {
                    setDeletableLoads(selectedLoads);
                }}
                disabled={isDisabled || !allSelectedOpenOrAssigned}
            >
                {L.delete()}
            </Button>}
        </div>
    );
};
