import React from 'react';
import { DateFiltering } from '../shared/load-table/date-filtering';
import { QueryKeys } from '../../api/config';
import { ComparisonFilter } from './table-components/filters/comparison-filter';
import { Table } from '@tanstack/react-table';
import { Load } from '../../types';
import { Loads2Presets } from './presets/loads2-presets';
import { CustomerFilter } from './table-components/filters/customer-filter';
import { ArbitraryTextFilter } from './table-components/filters/arbitrary-text-filter';

interface LoadTableToolbar2Props {
    isDrafts: boolean;
    isLoading: boolean;
    refetch: () => void;
    table: Table<Load>;
    resetTableState: () => void;
}

export const LoadTableToolbar2: React.FC<LoadTableToolbar2Props> = (props) => {
    const { isDrafts, refetch, table, resetTableState } = props;

    return (
        <div className='lt-toolbar'>
            <div style={{ display: 'flex', alignContent: 'space-between' }}>
                <DateFiltering
                    resetTableState={resetTableState}
                    tableKey={isDrafts ? QueryKeys.drafts : QueryKeys.loads}
                    refetch={refetch}
                />
                <Loads2Presets
                    table={table}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', columnGap: '1rem' }}>
                    <ComparisonFilter table={table} />
                    <div style={{ width: '15rem' }}>
                        <CustomerFilter
                            isDrafts={isDrafts}
                            table={table}
                        />
                    </div>
                </div>
                <ArbitraryTextFilter table={table} />
            </div>
        </div>
    );
};
