import { ConfirmationDialog } from '../shared/confirmation-dialog';
import { L } from 'harmony-language';
import React, { FC } from 'react';
import { useDeleteLoadsMutation } from '../../api/mutations/delete/use-delete-loads-mutation';
import { Load } from '../../types';
import { useDraftsKey, useLoadsKey } from '../../api/config-hooks';

interface DeleteLoadsModalProps {
    records: Load[];
    unselectFn: () => void;
    onResultCallback: () => void;
    isDrafts: boolean;
}

export const DeleteLoadsModal: FC<DeleteLoadsModalProps> = (props) => {
    const { records, unselectFn, onResultCallback, isDrafts } = props;
    const queryKey = isDrafts ? useDraftsKey() : useLoadsKey();
    const { mutate: deleteLoads } = useDeleteLoadsMutation(queryKey);

    return (
        <ConfirmationDialog
            open={records.length > 0}
            title={L.deleteConfirmationTitle()}
            message={L.deleteConfirmationMessage(`${records.length} ${L.loads().toLocaleLowerCase()}`)}
            cancelText={L.cancel()}
            confirmText={L.delete()}
            resultCallback={async (confirmed) => {
                onResultCallback();
                if (confirmed) {
                    unselectFn();
                    deleteLoads({ ids: records.map(x => x.id) });
                }
            }}>
        </ConfirmationDialog>
    );
};
