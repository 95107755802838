import { useQuery } from '@tanstack/react-query';
import { QueryKeys, typedFetchFunction } from '../config';
import { useDraftsKey, useLoadsKey } from '../config-hooks';
import { DateFilter, Load } from '../../types';
import { endOfDay, utcWithTz } from '../../utils/date-time-utils';
import { useAgisticsContext } from '../../app/agistics-context';
import { useUser } from './use-user';

const fetchLoadsWithDateFilter = async (baseKey: string, dateFilter: DateFilter, includedDates?: number[]) => {
    const includedDatesFilter = (includedDates: number[]) => {
        // completedAt should only be available/used on the loads page
        if (baseKey !== QueryKeys.loads) {
            return includedDates.filter(x => x !== 4);
        }
        return includedDates;
    }

    const params = {
        startDate: utcWithTz(dateFilter.from),
        endDate: utcWithTz(endOfDay(dateFilter.to)),
        ...(dateFilter.mode === 'custom' && includedDates && { includedDates: includedDatesFilter(includedDates) }),
    }

    const apiLoads = (await typedFetchFunction<Load[]>(baseKey, params)).data;

    return apiLoads;
};

export const useDraftsLoads = (isDrafts: boolean) => {
    const { dateFilters } = useAgisticsContext();
    const { user } = useUser();

    const baseKey = isDrafts ? QueryKeys.drafts : QueryKeys.loads;
    const dateFilter = dateFilters[baseKey];
    const queryKey = isDrafts ? useDraftsKey() : useLoadsKey();

    const query = useQuery({
        queryKey: queryKey,
        queryFn: async () => {
            return await fetchLoadsWithDateFilter(baseKey, dateFilter, user.preferences.includedDates);
        },
        staleTime: 30 * 1000,
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });

    return query;
}
