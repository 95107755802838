import React from 'react';
import { Stop } from '../../../../types';
import { ValidatedTextField } from '../../../shared/inputs/validated-text-field';
import { Validation } from '../../../shared/inputs/validated-text-field';
import { useDraftLoadStopContext } from '../../hooks/draft-load-stop-context';
import { editableStopInputProps } from '../../table-components/load-table2-stop-row';
import { stopHasCargo } from '../../../shared/load-table/stops-table-cells';

interface EditableStopQuantityProps {
    stop: Stop;
    validations?: Validation[];
}

export const EditableStopQuantity: React.FC<EditableStopQuantityProps> = (props) => {
    const { stop, validations } = props;
    const { onEditedStopChanged } = useDraftLoadStopContext();

    if (!stopHasCargo(stop)) {
        return null;
    }

    return (
        <ValidatedTextField
            // validations={validations}
            type='number'
            // required={required}
            // disabled={isSaving || stopEditDisabled}
            value={stop.quantity || ''}
            inputProps={{ min: 1, ...editableStopInputProps }}
            fullWidth
            onChange={(e) => {
                onEditedStopChanged({ quantity: Number(e.target.value) || null });
            }}
        />
    )
};
