import React, { FC } from 'react';
import { Load } from '../../../types';
import { validateDraft } from '../../drafts/utils/validate-drafts';
import { AgisticsTooltip } from '../../shared/agistics-tooltip';
import { PerformanceIcon } from './performance-icons';

interface DraftConflictIconProps {
    draft: Load;
    convertFromGrams: (g: number) => number;
}

// the performance on this is not great an icon with a tooltip :(
// done everything I can think of, to limit the amount of times it's actually rendered
export const DraftConflictIcon: FC<DraftConflictIconProps> = (props) => {
    const { draft, convertFromGrams } = props;

    const draftConflicts = validateDraft(draft, convertFromGrams);

    if (!draftConflicts.length) {
        return null;
    }

    const confictItems = draftConflicts.map(e => <div key={e}>{e}</div>);

    return (
        <AgisticsTooltip
            value={<PerformanceIcon icon='warning_amber' />}
            placement='left'
        >
            {confictItems}
        </AgisticsTooltip>
    )
}
