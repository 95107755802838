import { ColumnDef, ExpandedState, getCoreRowModel, getExpandedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, PaginationState, Row, RowSelectionState, Updater, useReactTable } from '@tanstack/react-table';
import { Load } from '../../types';
import { FIRST_STOP_COLUMN } from './use-loads2-columns';
import { useState } from 'react';
import { useUser } from '../../api/queries/use-user';
import { getPageSize } from './utils';
import { useLoadsGlobalFilter } from './table-components/filters/use-loads-global-filter';

// getSortedRowModel - https://github.com/TanStack/table/issues/4888
// provided getSortedRowModel function from @tanstack/react-table causes 2 renders when sorting
// spent quite a lot of time trying to write our own, but determined probably more work then it's worth at this time

export const useLoadTable2 = (
    isDrafts: boolean,
    columns: ColumnDef<Load, any>[],
    loads: Load[],
) => {
    const { user } = useUser();
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: getPageSize(isDrafts, user),
    });

    const loadsGlobalFilter = useLoadsGlobalFilter();

    const onPaginationChange = (updater: Updater<PaginationState>) => {
        if (typeof updater === 'function') {
            const newPagination = updater(pagination);
            setPagination(newPagination);
        }
    }

    const table = useReactTable({
        data: loads,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // this enables client side pagination
        // read above for performance info on getSortedRowModel
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: loadsGlobalFilter,
        // getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getExpandedRowModel: getExpandedRowModel(),
        onRowSelectionChange: setRowSelection,
        initialState: {
            sorting: [{ id: FIRST_STOP_COLUMN, desc: false }],
            rowSelection: rowSelection,
        },
        rowCount: loads.length,
        enableSortingRemoval: false,
        // if this is not set, it must use datatype of accessor to determine default sort?
        // might be asc or desc depending
        sortDescFirst: false,
        state: {
            pagination: pagination,
            rowSelection: rowSelection,
            expanded: expanded,
        },
        onExpandedChange: setExpanded,
        onPaginationChange: onPaginationChange,
    });

    return table;
}
