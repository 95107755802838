import React, { FC } from 'react';
import { OrganizationLocation, Stop } from '../../../types';
import { toSiteDisplayNameFromOrganizationLocation, toSublocationDisplay } from '../../../utils/data-mapping';
import { AgisticsTooltip } from '../../shared/agistics-tooltip';
import { L } from 'harmony-language';

interface AddressTooltipContentProps {
    organizationLocation: OrganizationLocation;
    sublocationDisplay: string | null;
}

const AddressTooltipContent: React.FC<AddressTooltipContentProps> = (props) => {
    const { organizationLocation, sublocationDisplay } = props;

    return (
        <div>
            <div>{L.address()}</div>
            <div>{L.localeAddressLine1(organizationLocation)}</div>
            {organizationLocation.address2 && organizationLocation.address2.trim().length ? <div>{L.localeAddressLine2(organizationLocation)}</div> : <></>}
            <div>{L.localeAddressLine3(organizationLocation)}</div>
            {sublocationDisplay
                ? <>
                    <hr />
                    <div>{L.subLocations()}</div>
                    <div>{sublocationDisplay}</div>
                </>
                : <></>
            }
        </div>
    );
};


interface LoadStopLocation2Props {
    organizationLocationId: number;
    organizationLocations: OrganizationLocation[];
    stops: Stop[];
}

export const LoadStopLocation2: FC<LoadStopLocation2Props> = (props) => {
    const { organizationLocationId, organizationLocations, stops } = props;

    const organizationLocation = organizationLocations.find(x => x.id === organizationLocationId);

    if (organizationLocation) {
        const siteDisplayName = toSiteDisplayNameFromOrganizationLocation(organizationLocation);

        const stopsWithSublocations = stops.filter(stop => stop.organizationSubLocationIds && stop.organizationSubLocationIds.length);
        const sublocationDisplay = toSublocationDisplay(stopsWithSublocations, organizationLocation);

        return (
            <div className='lt-cell-sub'>
                <AgisticsTooltip value={siteDisplayName}>
                    <AddressTooltipContent
                        organizationLocation={organizationLocation}
                        sublocationDisplay={sublocationDisplay}
                    />
                </AgisticsTooltip>
            </div>
        );
    }
}
