import { CarrierEditLoadOption, ChecklistResponseValue, CustomerTier, DeviceType, DriverReport, ImageMimeType, LanguageCode, LoadingThroughputEntityType, ResourceType, Timezone, TripEventMethod, TripEventType, UnitsWeight } from 'harmony-constants';
import { L, Units } from 'harmony-language';
import { CARGO_COLUMN, COMPLETED_AT_COLUMN, DESTINATION_COLUMN, DRIVER_COLUMN, FIRST_STOP_COLUMN, LAST_STOP_COLUMN, ORDER_NUMBER_COLUMN, ORIGIN_COLUMN, OriginDestinationFilterValue, STATUS_COLUMN, TRACTOR_COLUMN, TRAILER_COLUMN } from './components/loads2/use-loads2-columns';

/* START FROM harmony-types */
export type TractorTrailerResource = ResourceType.Tractor | ResourceType.Trailer;
export type EquipmentResourcePath<M extends TractorTrailerResource> = M extends ResourceType.Tractor ? 'tractor' : 'trailer';
export type ImageReferenceTable = 'stops' | 'organizationLocations';

export interface Image {
    id: number;
    blob: string;
    type: ImageMimeType;
    description?: string | null;
    table?: ImageReferenceTable | null;
    tableId?: number | null;
    metadata: string;
}

export interface Throughput {
    id: number;
    organizationId: number;
    organization: Organization;
    cargoTypeId: number;
    cargoType: CargoType;
    loadingEntityId: any;
    loadingEntityType: LoadingThroughputEntityType;
    stopType: StopType;
    cargoUnitsPerMinute: number;
    baselineLoadingMinutes: number;
    weightPerMinute: number;
    loadingEntity: OrganizationLocation;
    loadingTeam: LoadingTeam;
}

interface OrganizationSetting {
    id: number;
    organizationId: number;
    organization: Organization;
    driverReportStopActuals?: DriverReport;
    driverReportWeighStopWeight?: DriverReport;
    driverAssignCompartments?: DriverReport;
    driverAllowStopCompletionOutOfSequence?: boolean;
    driverAllowStopStartOutOfSequence?: boolean;
    etaDelayThresholdMinutes?: number;
    insideSiteInactiveMinutes?: number;
    outsideSiteInactiveMinutes?: number;
    enforceSameCustomerSplitLocations: boolean;
}

/* END FROM harmony-types */

export type KeysWithValsOfType<T,V> = keyof { [ P in keyof T as T[P] extends V ? P : never ] : P };

// export type LoadCache = {
//     data: Load[];
//     timeWindow: { from: string, to: string } | null;
// }

export type Load = {
    id: number;
    date: string;
    organizationId: number;
    isDraft?: boolean;
    tractorId: number | null;
    trailerId: number | null;
    transportingOrganizationId?: number | null;
    transportedByUserId: number | null;
    washOption?: WashOption | null;
    stops: Stop[];
    status: OrderStatus;
    orderGroup?: {
        guid: string,
        entryType: string
    },
    orderGroupGuid?: string;
    metadata?: {
        cargo?: {
            averageWeight?: number;
            salmonellaResult?: SalmonellaResult
        };
        showTrailerBoxes?: boolean
    };
    mergedFromOrderIds: number[] | null;
    timeSeconds: number | null;
    distanceMeters: number | null;
}

export type OrderStatus = 'Open' | 'Assigned' | 'In Transit' | 'Delivered' | 'Merged';
export type SalmonellaResult = 'negative'
    | 'suspicious'
    | 'mildPositive'
    | 'highPositive'

export type Stop = {
    id: number;
    orderId: number;
    arrivalTime: string | null;
    cargoTypeId: number | null;
    sequence: number;
    quantity: number | null;
    actualQuantity: number | null;
    durationMinutes?: number | null;
    type: StopType;
    status: StopStatus;
    loadingTeamId?: number | null;
    organizationLocationId: number;
    organizationSubLocationId?: number | null;
    organizationSubLocationIds: number[] | null;
    lotId: string | null;
    orderNumber: string | null;
    productionPlan: string | null;
    compartments?: number[] | null; // [1,3,5] would mean compartments 1,3,5 are in use
    cargoPerCompartment?: number | null;
    splitId?: number | null;
    notes?: string | null;
    comments?: string | null;
    orgLocation?: OrganizationLocation;
    orgSubLocation?: OrganizationSubLocation | null;
    customerOrderNumber: string | null;
    requiredBegin: string | null;
    requiredEnd: string | null;
    readyTime: string | null;
    imageIds?: number[] ;
    domKey?: number,
    weight: number | null;
    actualWeight: number | null;
    metadata: Record<string, any> | null;
    timeSeconds: number | null;
    distanceMeters: number | null;
    deliveryTicket?: string | null;
    startedAt: string | null;
    completedAt: string | null;
    completedByUserId: number | null;
}

export type LaxStop = Omit<Stop, "id"> & { id?: number } //Id becomes optional for unpersisted stop scenarios

export type StopType = 'Origin' | 'Destination' | 'Weigh' | 'Waypoint' | 'Wash'
export type StopStatus = 'Not Started' | 'Enroute' | 'Arrived' | 'Complete'
export type WashOption = 'None' | 'Before' | 'After' | 'Both'
export type ResourcePageResourceType = 'location' | 'loadingTeam' | 'user' | 'tractor' | 'trailer';

export const NOTIFICATION_TYPES = {
    Email: 'Email',
    Text: 'Text',
    Push: 'Push',
    Webhook: 'Webhook'
}
export type NotificationType =
    typeof NOTIFICATION_TYPES.Email
    | typeof NOTIFICATION_TYPES.Text
    | typeof NOTIFICATION_TYPES.Push
    | typeof NOTIFICATION_TYPES.Webhook;

export const USER_EVENT_TYPES = {
    ETAThreshold: 'ETAThreshold',
    StopDelivery: 'StopDelivery'
}
export type UserEventType = typeof USER_EVENT_TYPES.ETAThreshold | typeof USER_EVENT_TYPES.StopDelivery;

type BookingResource = {
    begin: Date;
    end: Date;
    lotId: string | null;
    orderId: number;
    orderNumber: string | null;
    organizationSubLocationIds: number[] | null;
    resourceId: number;
    stopSequence: number | null;
}

export type Booking = {
    loadingTeam: BookingResource[];
    location: BookingResource[];
    tractor: BookingResource[];
    trailer: BookingResource[];
    user: BookingResource[];
}

export type DownTime = {
    id: number;
    resourceType: string;
    resourceId: number;
}

export interface TractorResource {
    id: number;
    type: 'tractor';
    stop: undefined;
}

export interface TrailerResource {
    id: number;
    type: 'trailer',
    cargoTypeIds: number[];
    stop: undefined;
}

export interface UserResource {
    id: number;
    type: 'user';
    stop: undefined;
}

export interface LocationResource {
    id: number;
    type: 'location',
    cargoTypeIds: number[],
    stop: Stop
}

export interface LoadingTeamResource {
    id: number;
    type: 'loadingTeam'
    stop: Stop
}

export type Resource = TractorResource | TrailerResource | UserResource | LocationResource | LoadingTeamResource

export type Equipment = {
    id: number;
    organizationId: number;
    userDisplayName: string;
    licensePlate: string | null;
    vinNumber: string | null;
    contract: boolean;
    enabled: boolean;
    weight: number | null;
    checklistTemplateId: number | null;
}

export type EditCreateTractorType  = Tractor | Omit<Tractor, 'id'>;

export type Tractor = Equipment & {
    make: string | null;
    model: string | null;
}

export type EditCreateTrailerType  = Trailer | Omit<Trailer, 'id'>;

export type Trailer = Equipment & {
    maxCargoWeight: number;
    deviceId: number | null;
    trailerCargoTypes: { cargoTypeId: number, compartmentCount: number }[]
}

export interface StateUser {
    contact: Contact;
    contactId: number;
    email: string;
    enabled: boolean;
    firstName: string;
    id: number;
    lastName: string;
    name: string;
    organization: Organization;
    organizationId: number;
    phone: string | null;
    // preferences
    roles: Role[];
    sublocationTagging: boolean;
    username: string;
}

export interface User {
    id: number;
    organizationId: number;
    tractorId?: number | null;
    name: string;
    firstName: string;
    lastName: string;
    phone?: string;
    email?: string;
    username: string;
    roles?: Role[];
    organization: Organization;
    enabled: boolean;
    preferences: Preferences;
    contact: Contact;
    driverInfo?: DriverInfo;
}

interface Preset2StringFilters {
    field: typeof ORDER_NUMBER_COLUMN | typeof FIRST_STOP_COLUMN | typeof LAST_STOP_COLUMN | typeof COMPLETED_AT_COLUMN;
    value: string;
}

interface Preset2OriginDestinationFilters {
    field: typeof ORIGIN_COLUMN | typeof DESTINATION_COLUMN;
    value: OriginDestinationFilterValue;
}

interface Preset2ArrayFilters {
    field: typeof CARGO_COLUMN | typeof DRIVER_COLUMN | typeof TRACTOR_COLUMN | typeof TRAILER_COLUMN | typeof STATUS_COLUMN;
    value: number[];
}

export interface Preset2 {
    name: string;
    default: boolean;
    filters: Preset2ArrayFilters[] | Preset2OriginDestinationFilters[] | Preset2StringFilters[];
}

interface TablePreference {
    pageSize: number;
}

export interface Preferences {
    tables: Record<string, TablePreference>;
    includedDates?: number[];
    presets?: any;
    presets2?: Preset2[];
    removedDrivers?: number[];
    removedTrailers?: number[];
    draftsLoadsEditable?: boolean;
    showEstimatedTotalTripTime?: boolean;
}

export interface Role {
    id: number;
    role: string;
    permissions?: Permission[];
}

export interface Permission {
    id: number;
    name: string;
    description: string;
}

export interface DriverInfo {
    defaultTractorId: number | null;
    defaultTrailerId: number | null;
}

export interface Driver extends User {
    driverInfo?: DriverInfo;
    organization: Organization;
}

export type GeoLocation = {
    latitude: number,
    longitude: number,
    radius: number | null,
    bbox: Bbox;
}

export type CreateOrganizationSubLocation = Omit<OrganizationSubLocation, 'id' | 'organizationLocationId'>;

export type OrganizationSubLocation = {
    id: number,
    name: string,
    customGeofenceId?: number | null,
    deletedAt?: string | null,
    organizationLocationId: number,
    updatedAt?: string | null,
    customGeofence: GeoLocation | null;
}

// TODO: I think this was because customGeofence was option, which is no longer the case.
export type OrganizationLocationWithCustomGeofence = Omit<OrganizationLocation, 'customGeofence'> & { customGeofence: GeoLocation };

export type CreateOrganizationLocation  = Omit<OrganizationLocation, 'id'>;

export type OrganizationLocation = {
    id: number;
    organizationId: number;
    name: string;
    organizationLocationTypeId: number;
    orgLocationType?: LocationType;
    hasScale: boolean;
    orgSubLocations: OrganizationSubLocation[];
    customGeofence: GeoLocation;
    users: SiteManager[];
    customerId: number | null;
    description: string | null;
    enabled: boolean;
    loadingTeamId: number | null;
    premiseId: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    postalCode: string | null;
    country: string | null;
}

export type LocationType = {
    id: number;
    category: string;
    name: string;
    description: string;
    cargoTypeCategories?: string[];
    receivesCargoTypeIds: number[];
    shipsCargoTypeIds: number[];
}

export type LoadingTeam = {
    id: number;
    name: string;
    email: string;
    users: User[];
}

export type SiteManager = {
    id: number,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    preferences: string
};

export type CargoType = {
    id: number;
    label: string;
    category: string;
}

export type SiteIssue = {
    id: number;
    organizationLocationId: number;
    issueLanguageKey: keyof typeof L,
    notes?: string;
    createdByUserId: number;
    updatedAt: string | Date;
    createdAt: string | Date;
    resolvedByUserId?: number | null;
    resolvedAt?: string | Date | null;
    resolutionNotes: string | null;
    imageIds: number[] | null;
}

export type SiteIssueType = {
    id: number;
    key: string;
    cargoTypeIds: number[] | null;
}

export interface DeviceLocation extends DeviceLocationIds {
    latitude: number,
    longitude: number,
    heading: number | null,
    speed: number | null,
    accuracy: number | null,
    timestamp: Date,
    device?: Device,
}

interface DeviceLocationIsDuplicateId {
    isDuplicateDeviceId?: boolean,
    isDuplicateOrderId?: boolean,
    isDuplicateUserId?: boolean,
    isDuplicateTractorId?: boolean,
    isDuplicateTrailerId?: boolean
}

export interface DeviceLocationIds {
    deviceId: number,
    userId: number,
    orderId: number,
    tractorId: number,
    trailerId: number
}

export interface DeviceLocationDupeFieldCheck {
    deviceId?: Array<DeviceLocationIds['deviceId']>
    userId?: Array<DeviceLocationIds['userId']>,
    orderId?: Array<DeviceLocationIds['orderId']>,
    tractorId?: Array<DeviceLocationIds['tractorId']>,
    trailerId?: Array<DeviceLocationIds['trailerId']>
}

export interface DeviceLocationParsed extends DeviceLocation, DeviceLocationIsDuplicateId { }

export type Customer = {
    id: number,
    organizationId: number,
    customerName: string
}

export type Organization = {
    id: number;
    name: string;
    tier: CustomerTier;
    language: LanguageCode;
    timezone: Timezone;
    units: Units;
    unitsWeight: UnitsWeight | null,
    etaThresholdMinutes: number;
    longitude: number;
    latitude: number;
    features?: Feature[];
    isCarrier: boolean;
    carriers: Organization[];
    clients: Organization[];
    organizationCarrier: OrganizationCarrier;
    cargoTypes: CargoType[];
    settings?: OrganizationSetting;
}

export type Feature = {
    id: number;
    name: string;
}

export type OrganizationCarrier = {
    id: number | null;
    organizationId: number;
    carrierOrganizationId: number;
    isActive: boolean;
    carrier?: {
        name: string
    },
    loadDriver: CarrierEditLoadOption,
    loadTractor: CarrierEditLoadOption,
    loadTrailer: CarrierEditLoadOption,
}

export type TripEvent = {
    id: number;
    type: TripEventType;
    userId: number | null;
    deviceId: number;
    //timestamp: string | Date;
    timestamp: Date;
    latitude: number;
    longitude: number;
    accuracy: number | null;
    orderId: number | null;
    stopId: number | null;
    organizationId: number | null;
    organizationLocationId: number | null;
    tractorId: number | null;
    trailerId: number | null;
    metadata: {
        comment?: string;
        method?: TripEventMethod;
        duration?: number;
    };
    organizationSubLocationIds: number[] | null;
    initiatingId: number | null;
    device?: Device;
}

export type Task = {
    id: number;
    completedAt: string | Date;
    createdByUserId: number;
    assignedToUserId: number | null;
    completedByUserId: number | null;
}

export type ProductionCard = {
    id: number;
    guid: string;
    cargoTypeId: number;
    metadata: any;
    orderGroupId?: number;
    orderNumber?: string | null;
    organizationId: number;
    productionPlan?: string | null;
    quantity: number;
    stops: Stop[];
}

export type CarrierConfigurableLoadSetting = 'loadDriver' | 'loadTractor' | 'loadTrailer';

export type Contact = {
    id: number;
    organizationId: number;
    name: string;
    language: LanguageCode | null;
    timezone: Timezone | null;
    units: Units | null;
    enabled: boolean;
    unitsWeight: UnitsWeight | null;
    contactNotificationMethods: ContactNotificationMethod[];
    interactiveUser?: User | null;
    contactPreferences?: ContactPreferences[];
};

export type ContactNotificationMethod = {
    id?: number;
    contactId?: number;
    notificationType: NotificationType;
    destination: string;
};

export type ContactPreferences = {
    // id?: number;
    contactId: number;
    notificationEventTypeId: number;
    notificationType: NotificationType;
}

export type NotificationConfig = {
    id?: number;
    organizationId: number;
    notificationEventTypeId: number;
    contactIds: number[];
    stopCargoTypeIds: number[] | null;
    trailerCargoTypeIds: number[] | null;
    destinationSiteIds: number[] | null;
    originSiteIds: number[] | null;
    customerIds: number[] | null;
};

/**
 * Deprecated: name, contactId, organizationId are no longer applicable for every resource type
 */
export type ResourceTypeManagement = (
    | OrganizationLocation
    | LoadingTeam
    | User
    | Tractor
    | Trailer
    | Contact
    | Customer
    ) & { name: string; contactId?: number; organizationId?: number };

export interface TimeWindow {
    to: string;
    from: string;
}

export interface TimeWindowWithPreset extends TimeWindow {
    preset?: number;
}

export interface DateFilter {
    page: string;
    mode: 'day' | 'week' | 'custom';
    offset: number;
    from: string;
    to: string;
    includeDrafts: boolean;
    // includedDates: number[];
}

export type EditCreateAvoidanceZoneType = AvoidanceZone | Omit<AvoidanceZone, 'id' | 'boundingBox'>;

export interface AvoidanceZone {
    id: number;
    name: string;
    description: string;
    organizationId: number | null;
    deactivatedAt: string | null;
    geoLocation: {
        latitude: number;
        longitude: number;
        bbox: Bbox;
    },
    // edit/create screen does not use this property, but is used elsewhere
    boundingBox: Bbox;
    weightThreshold: number | null;
    cargoTypeIds: number[] | null;
}

export interface Bbox {
    topLeft: {
        latitude: number;
        longitude: number;
    },
    bottomRight: {
        latitude: number;
        longitude: number;
    },
}

export interface Device {
    id: number;
    type: DeviceType;
    clientId: string;
}

export interface ServiceAccount {
    id: number;
    organizationId: number;
    userType: string;
    updatedAt: string;
    createdAt: string;
    name: string;
}

export interface Point {
    latitude: number;
    longitude: number;
}

export interface HerePoint {
    lat: number;
    lng: number;
}

export interface ChecklistTemplateReading {
    key: string;
    type: 'number' | 'string';
    required: boolean;
}

export interface ChecklistTemplateTemplate {
    default: string[];
    readings?: ChecklistTemplateReading[];
}

export interface ChecklistTemplate {
    id: number;
    organizationId: number;
    organization: Organization;
    resourceType: TractorTrailerResource;
    template: ChecklistTemplateTemplate[];
    validResponses: number;
    timeToExpirationMinutes: number;
    requireOnUserSwitch: boolean;
    name: string;
    default: boolean;
}
export interface Checklist {
    id: number;
    organizationId: number;
    organization: Organization;
    type: string;
    locationId: number;
    location: Location;
    completedByUserId: number;
    completedBy: User;
    resourceType: ResourceType;
    resourceId: number;
    usedResponses: number;
    hasNotes: boolean;
    checklist: ChecklistChecklist;
    tractor: Tractor;
    trailer: Trailer;
}

export type ChecklistKey =
    'checklistItemDriverControls'
    | 'checklistItemSteering'
    | 'checklistItemHeadlightsAndLights'
    | 'checklistItemHeaterAndDefroster'
    | 'checklistItemWindshieldWiperAndWasher'
    | 'checklistItemEmergencyMaterial'
    | 'checklistItemSeat'
    | 'checklistItemGlassAndMirrors'
    | 'checklistItemDoorsAndOtherOpenings'
    | 'checklistItemCouplingDevices'
    | 'checklistItemFrameAndCargoBody'
    | 'checklistItemTires'
    | 'checklistItemWheelsHubsAndFasteners'
    | 'checklistItemSuspension'
    | 'checklistItemFuelSystem'
    | 'checklistItemExhaustSystem'
    | 'checklistItemElectricBrakeSystem'
    | 'checklistItemHydraulicBrakeSystem'
    | 'checklistItemPneumaticBrakeSystem'
    | 'checklistItemGreaseBoomAugerBearings'
    | 'checklistItemGreaseBoomCylinderPins'
    | 'checklistItemGreaseBoomPivot'
    | 'checklistItemGreaseLowerVerticalRotation'
    | 'checklistItemGreaseTroughAugerBearings'
    | 'checklistItemGreaseUpperVerticalBearingClamp'
    | 'checklistItemGreaseVerticalAugerBearings'
    | 'checklistItemGreaseVerticalRotationPilotRing'
    | 'checklistItemInspectAndGreaseFifthWheel'
    | 'checklistItemLubeChain';

type ChecklistTemplateTypeKey = 'default' | 'weekly';

export interface ChecklistReadingItem {
    key: string,
    shownText?: string | undefined,
    value: number | string | undefined
}

export interface ChecklistGroupItem {
    key: ChecklistKey;
    shownText: string;
    response: ChecklistResponseValue;
    notes: string | null | undefined;
}

interface ChecklistGroup {
    key: ChecklistTemplateTypeKey;
    shownText: string;
    items: ChecklistGroupItem[];
}

export interface ChecklistChecklist {
    notes?: string | null | undefined;
    odometerReading?: number | string | null | undefined;
    groups: ChecklistGroup[];
    readings: ChecklistReadingItem[];
    inspectionId?: number;
}

export interface EditCreateComponentProps<T> {
    resource: T;
    closeModal: () => void;
}

export type GrossWeightObject = {
    grossWeight: string;
    tooltipText: string;
} | null;

export type CreateStandardRoute  = Omit<StandardRoute, 'id'>;

export interface StandardRoute {
    id: number;
    // organizationId: number;
    originId: number;
    destinationId: number;
    flexiblePolyline: string;
    deletedAt: string | null;
}
