import { useAgisticsContext } from '../app/agistics-context';
import { QueryKeys } from './config';
import { useUser } from './queries/use-user';

// includedDates works a bit different as it's stored in userPreferences and there is only 1 value (not a value for each drafts/loads)
// so we are not interested in keeping different state between the 2 keys

export const useDraftsKey = () => {
    const { dateFilters } = useAgisticsContext();
    const { user } = useUser();
    const draftFilters = dateFilters[QueryKeys.drafts];
    const draftsKey = [QueryKeys.drafts, draftFilters.mode, draftFilters.offset, draftFilters.from, draftFilters.to, user.preferences.includedDates || []];

    return draftsKey;
}

export const useLoadsKey = () => {
    const { dateFilters } = useAgisticsContext();
    const { user } = useUser();
    const loadFilters = dateFilters[QueryKeys.loads];
    const loadsKey = [QueryKeys.loads, loadFilters.mode, loadFilters.offset, loadFilters.from, loadFilters.to, user.preferences.includedDates || []];

    return loadsKey;
}

export const useProductionCardGroupsKey = () => {
    const { dateFilters } = useAgisticsContext();
    const productionCardsFilters = dateFilters[QueryKeys.productionCardGroups];
    const productionCardsKey = [QueryKeys.productionCardGroups, productionCardsFilters.mode, productionCardsFilters.offset, productionCardsFilters.from, productionCardsFilters.to];

    return productionCardsKey;
}
