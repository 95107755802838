import { Button, TextField } from '@mui/material';
import { L } from 'harmony-language';
import React, { useState } from 'react';

interface PresetsModalProps {
    handlePresetSave: (s: string) => void;
}

export const PresetsModal: React.FC<PresetsModalProps> = (props) => {
    const { handlePresetSave } = props;
    const [name, setName] = useState<string>('');

    return (
        <>
            <TextField
                variant='standard'
                onChange={(e) => {
                    setName(e.target.value);
                }}
            />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '1rem',
                }}
            >
                <Button
                    variant='contained'
                    disabled={!name.length}
                    onClick={() => {
                        handlePresetSave(name);
                    }}
                >
                    {L.save()}
                </Button>
            </div>
        </>
    );
};
