import { useMemo } from 'react';
import { Driver, Tractor, Trailer } from '../../../types';

// thoughts: previously we computed these in each component instance
// going to try and just compute once for the page and pass the list down

// TODO: all the enabled/disabled/carrier logic
export const useLoadsEditableItems = (drivers: Driver[], trailers: Trailer[], tractors: Tractor[]) => {
    const driverMultiItems = useMemo(() => drivers.map(driver => ({ id: driver.id, value: driver.name })), [drivers]);
    const trailerMultiItems = useMemo(() => trailers.map(trailer => ({ id: trailer.id, value: trailer.userDisplayName })), [trailers]);
    const tractorMultiItems = useMemo(() => tractors.map(tractor => ({ id: tractor.id, value: tractor.userDisplayName })), [tractors]);

    return {
        driverMultiItems: driverMultiItems,
        trailerMultiItems: trailerMultiItems,
        tractorMultiItems: tractorMultiItems,
    };
}
