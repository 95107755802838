import React from 'react';
import { Stop } from '../../../../types';
import { ValidatedTextField, Validation } from '../../../shared/inputs/validated-text-field';
import { useWeights } from '../../../shared/hooks/use-weights';
import { editableStopInputProps } from '../../table-components/load-table2-stop-row';
import { InputAdornment } from '@mui/material';
import { useDraftLoadStopContext } from '../../hooks/draft-load-stop-context';
import { stopHasCargo } from '../../../shared/load-table/stops-table-cells';

interface EditableStopWeightProps {
    stop: Stop;
    validations?: Validation[];
}

export const EditableStopWeight: React.FC<EditableStopWeightProps> = (props) => {
    const { stop, validations } = props;
    const { onEditedStopChanged } = useDraftLoadStopContext();
    const { weightAbbreviation, convertFromGrams, convertToGrams, isDecimalWeight } = useWeights();

    if (!stopHasCargo(stop)) {
        return null;
    }

    return (
        <ValidatedTextField
            // validations={validations}
            type='number'
            // required={required}
            // disabled={isSaving || stopEditDisabled}
            value={convertFromGrams(stop.weight || 0) || ''}
            inputProps={{ min: 0, step: isDecimalWeight ? .01 : 1, ...editableStopInputProps }}
            fullWidth
            onChange={(e) => {
                const changedWeight = Number(e.target.value);
                const grams = convertToGrams(changedWeight);
                onEditedStopChanged({ weight: grams || null });
            }}
            InputProps={{
                endAdornment: <InputAdornment position='end'>{weightAbbreviation}</InputAdornment>,
            }}
        />
    );
};
