import React from 'react';
import { styled } from '@mui/material/styles';
import { LoadEditFunctions, useLoadContext } from '../load-context';
import { IconButton } from '@mui/material';
import { L } from 'harmony-language';
import { Load } from '../../../types';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import { useCarrier } from '../../user/selectors/use-permissions';
import { EditableStopsTableRow } from './editable-stops-table-row';
import CompartmentsHeader from './compartments-header';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { LoadTableColumn } from '../../loads/use-loads-columns';

const gridTemplateColumnsEditable = '1.5fr 3.5fr 1.5fr 2.5fr 2.5fr 30px 1.5fr 1.5fr 2fr .75fr 1.75fr 1.75fr 1.75fr 30px 30px';

export const StopsTableTable = styled('div')(({ theme }) => ({
    '& tr:last-child > td': {
        borderBottom: 'unset'
    },
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    boxShadow: theme.shadows[3],
    border: '1px solid #e0e0e0',
}));

const StopsTableGridRowHeader = styled('div')({
    display: 'grid',
    alignItems: 'center',
    gridAutoFlow: 'column',
    backgroundColor: '#eee',
    borderTop: 'unset',
    '& > div': {
        padding: '3px 5px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
});

export const StopsTableGridRow = styled('div')({
    display: 'grid',
    alignItems: 'center',
    borderTop: '1px solid #e0e0e0',
    '& > div': {
        padding: '3px 5px',
        overflow: 'hidden'
    }
});

export const StopsTableRowPrefix = styled('div')({
    display: 'grid',
    gridTemplateColumns: '2% 2% auto',
});

export const StopsTableGridRowAnother = styled('div')({
    display: 'grid',
    alignItems: 'center',
    borderTop: '1px solid #e0e0e0',
    '& > div': {
        padding: '3px 5px',
        overflow: 'hidden'
    },
    gridAutoFlow: 'column',
    gridTemplateColumns: '3fr 2.5fr 2.5fr 2.5fr 12fr',
});

export const gridRowEditable = {
    borderTop: '1px solid transparent',
    gridTemplateColumns: gridTemplateColumnsEditable,
}

export const StopsTable: React.FC<{ columns: LoadTableColumn[] }> = (props) => {
    const { columns } = props;
    const { load, editFunctions } = useLoadContext();
    const [headerVis, setHeaderVis] = React.useState<boolean[]>(Array.from(Array(load.stops.length).fill(false)));

    const handleVisChange = (i: number, childVis: boolean) => {
        // need to define and spread this or does not cause a re-render
        const newHeaderVis = [...headerVis];
        newHeaderVis[i] = childVis;
        setHeaderVis(newHeaderVis);
    }

    // TODO: Rework as this is / will always be true going forward
    if (editFunctions) {
        return <EditableStopTable columns={columns} editFunctions={editFunctions} load={load}
                                  isSaving={editFunctions?.isSaving} headerVis={headerVis} handleVisChange={handleVisChange} />
    }
};

const EditableStopTable: React.FC<{ columns: LoadTableColumn[], editFunctions: LoadEditFunctions, load: Load, isSaving?: boolean, headerVis: boolean[], handleVisChange: (i: number, rowVis: boolean) => void }> = (props) => {
    const { columns, editFunctions: { addStop, deleteStop, handleOnDragEnd }, load, isSaving, headerVis, handleVisChange } = props;
    const currentUserIsCarrierOrg = useCarrier();

    return (
        <StopsTableTable>
            <StopsTableRowPrefix>
                <div style={{ backgroundColor: '#eee' }}/><div style={{ backgroundColor: '#eee' }}/>
                <StopsTableGridRowHeader sx={gridRowEditable}>
                    {columns.map((c, i) => {
                        return (
                            <div key={i}>
                                {typeof c.header === 'string' ? c.header : <c.header/>}
                            </div>
                        );
                    })}
                </StopsTableGridRowHeader>
            </StopsTableRowPrefix>
            {headerVis.some(bool => bool) ?
                <StopsTableRowPrefix>
                    <div /><div />
                    <StopsTableGridRowAnother>
                        <div>{L.customerOrderNumber()}</div>
                        <div>{L.loadingTeam()}</div>
                        <div>{L.requiredBegin()}</div>
                        <div>{L.requiredEnd()}</div>
                        <div><CompartmentsHeader /></div>
                    </StopsTableGridRowAnother>
                </StopsTableRowPrefix>
            : null}
            <DndContext
                onDragEnd={handleOnDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                collisionDetection={closestCenter}
            >
                <SortableContext
                    items={load.stops.map(x => x.sequence)}
                    strategy={verticalListSortingStrategy}
                >
                    {load.stops.map((stop, i) => (
                        <EditableStopsTableRow load={load}
                            key={stop?.id ? stop.id : stop.domKey}
                            stop={stop}
                            i={i}
                            isSaving={isSaving}
                            currentUserIsCarrierOrg={currentUserIsCarrierOrg}
                            columns={columns}
                            deleteStop={deleteStop}
                            handleVisChange={handleVisChange}
                        />
                    ))}
                    <div>
                        <IconButton
                            title={L.add()}
                            size='small'
                            color='primary'
                            disabled={currentUserIsCarrierOrg}
                            onClick={() => addStop()}
                        >
                            <AddBoxOutlined color={currentUserIsCarrierOrg ? 'disabled' : 'primary'} />
                        </IconButton>
                    </div>
                </SortableContext>
            </DndContext>
        </StopsTableTable>
    );
};
