import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { L } from 'harmony-language';
import { Load } from '../../../types';

const deleteLoads = async (options: { ids: number[] }) => {
    const ids = options.ids;

    await axios.delete(`${API_BASE}/api/orders`, { data: ids });
}

export const useDeleteLoadsMutation = (queryKey: (string | number | number[])[]) => {
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteLoads,
        onMutate: async (variables) => {
            await queryClient.cancelQueries(queryKey);

            const previousCache = queryClient.getQueryData<Load[]>(queryKey);

            if (previousCache) {
                const ids = variables.ids;
                const filteredLoads = previousCache.filter(load => !ids.includes(load.id));

                queryClient.setQueryData(queryKey, filteredLoads);
            }

            return previousCache;
        },
        onError: (error, ids, context) => {
            if (context) {
                queryClient.setQueryData<Load[]>(queryKey, context);
            }
        },
        onSuccess: (_, options) => {
            snackbar(L.bulkDeleteSnackbarSuccess(options.ids.length.toString()));
        },
        onSettled: () => {}
    });

    return mutation;
};
