import { Header } from '@tanstack/react-table';
import React from 'react';
import { Load } from '../../../types';
import { TableSortLabel } from '@mui/material';

interface LoadTable2HeaderCellProps {
    header: Header<Load, number>;
    label: string;
}

export const LoadTable2HeaderCell: React.FC<LoadTable2HeaderCellProps> = (props) => {
    const { header, label } = props;
    const canSort = header.column.getCanSort();
    const isSorted = header.column.getIsSorted();

    return (
        <div
            className={canSort ? 'cursor-pointer' : undefined}
            style={{ textAlign: 'center', fontWeight: 'bold' }}
            onClick={() => {
                if (canSort) {
                    header.column.toggleSorting();
                }
            }}
        >
            {label}
            {canSort && <TableSortLabel
                active={Boolean(isSorted)}
                direction={isSorted ? isSorted : undefined}
            />}
        </div>
    );
};
