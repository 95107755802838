import React, { useEffect, useMemo, useState } from 'react';
import { createColumnHelper, Row } from '@tanstack/react-table'
import { CargoType, Driver, Load, OrderStatus as OrderStatusFromTypes, OrganizationCarrier, OrganizationLocation, Tractor, Trailer } from '../../types';
import { L } from 'harmony-language';
import { sortByDriver, sortByStatus, sortByStop, sortByStopCompletedTime, sortByStopTime, sortByTractor, sortByTrailer } from '../shared/table/utils/sorting-functions';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { filterStopByDestination, filterStopByOrigin, loadToFirstStop, loadToLastStop, orderToCargoQuantities, orderToLastCompleted, orderToLoadingDurationMinutes, toDriverDisplayName, toTractorDisplayName, toTrailerDisplayName } from '../../utils/data-mapping-utils';
import { PerformanceIconButton } from './loads2-table-cells/performance-icons';
import { useTractorTrailer } from '../../api/queries/use-tractor-trailer';
import { LoadOrderNumbers2 } from './loads2-table-cells/load-order-numbers2';
import { LoadStopLocation2 } from './loads2-table-cells/load-stop-location2';
import { LoadStopTime2 } from './loads2-table-cells/load-stop-time2';
import { ORDER_STATUSES } from '../../constants/constants';
import { useWeights } from '../shared/hooks/use-weights';
import { PerformanceCheckbox } from './loads2-table-cells/performance-checkbox';
import { PerformanceExpander } from './loads2-table-cells/performance-expander';
import { Features, OrderStatus, StopType } from 'harmony-constants';
import { useFeatures } from '../user/selectors/use-permissions';
import { useTravelTimesLoad } from '../travel-times/use-travel-times';
import { CircularProgress } from '@mui/material';
import { NoMUITravelTimeTooltip } from '../travel-times/travel-time-tooltip';
import { getDuration } from '../../utils/date-time-utils';
import { AgisticsTooltip } from '../shared/agistics-tooltip';
import { doesValueMatch } from '../shared/table/utils/search-functions';
import { DraftConflictIcon } from './loads2-table-cells/draft-conflict-icon';
import { anyColumnFiltered } from './utils';
import { useUser } from '../../api/queries/use-user';
import { MultiFilter } from './table-components/filters/multi-filter';
import { toSiteDisplayNameFromOrganizationLocation } from '../../utils/data-mapping';
import { LoadTable2HeaderCell } from './table-components/load-table2-header-cell';
import { useCargoTypes } from '../../api/queries/use-cargo-types';
import { TextFilter } from './table-components/filters/text-filter';
import { AgisticsMultiSelect } from '../shared/multi-select/agistics-multi-select';
import { AgisticsSelect } from '../shared/agistics-select';
import { useLoadsEditableItems } from './hooks/use-loads-editable-items';
import { useDraftLoadContext } from './hooks/draft-load-context';

export interface OriginDestinationFilterValue {
    value: number[];
    sublocationId: number | undefined;
}

// columnIds - I'm pretty sure if no id is set, fallback is header, which is potentially translated...
// these are also the id for presets in userPreferences
export const ORDER_NUMBER_COLUMN = 'order_number_column';
export const ORIGIN_COLUMN = 'origin_column';
export const DESTINATION_COLUMN = 'destination_column';
export const FIRST_STOP_COLUMN = 'first_stop_column';
export const LAST_STOP_COLUMN = 'last_stop_column';
export const COMPLETED_AT_COLUMN = 'completed_at_column';
const TRIP_TIME_COLUMN = 'trip_time_column'; // completeness
export const CARGO_COLUMN = 'cargo_column';
export const DRIVER_COLUMN = 'driver_column';
export const TRACTOR_COLUMN = 'tractor_column';
export const TRAILER_COLUMN = 'trailer_column';
export const STATUS_COLUMN = 'status_column';

export const useLoads2Columns = (
    isDrafts: boolean,
    handleEditableLoadOpen: (l: Load) => void,
    handleDeliveryLogOpen: (l: Load) => void,
    handleUnmergeOpen: (l: Load) => void,
) => {
    const columnHelper = createColumnHelper<Load>();
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: drivers = [] } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { data: carriers = [] } = useOrganizationQuery<OrganizationCarrier[]>(OrgQueryKeys.carriers);
    const { tractors = [], trailers = [] } = useTractorTrailer();
    const { cargoTypeList } = useCargoTypes();
    const orderStatuses = Object.values(ORDER_STATUSES());
    const { convertFromGrams, convertFromGramsDisplay } = useWeights();
    const hasUnmergeFeature = useFeatures(Features.UnmergeDrafts);
    const { user } = useUser();
    const draftsLoadsEditable = user.preferences.draftsLoadsEditable ?? true;
    const showEstimatedTotalTripTime = user.preferences.showEstimatedTotalTripTime;

    // const driverMultiItems = React.useMemo(() => drivers.map(driver => ({ id: driver.id, value: driver.name })), [drivers]);

    const loadsEditableItems = useLoadsEditableItems(drivers, trailers, tractors);

    // The problem I was having is Loads2Presets is loaded before column facetedKeys are known, so can't
    // set the filter value on the column until those are known.
    // The idea behind this is, set this value and then if any filter changes set this to undef
    // which then makes all the other filters 'ignore' the default until page change / reload.
    const [defaultPreset, setDefaultPreset] = useState(user.preferences.presets2?.find(x => x.default));

    useEffect(() => {
        if (!defaultPreset) {
            const userDefault = user.preferences.presets2?.find(x => x.default);
            if (userDefault) {
                setDefaultPreset(userDefault);
            }
        }
    }, [isDrafts]);

    const columns = useMemo(() => {
        const c = [
            columnHelper.display({
                id: 'actions',
                header: (headerContext) => {
                    const { table } = headerContext;
                    const anyColFiltered = anyColumnFiltered(table);

                    return (
                        <div style={{ textAlign: 'left' }}>
                            <PerformanceCheckbox
                                // checked={table.getIsAllRowsSelected()}
                                checked={table.getIsAllPageRowsSelected()}
                                // indeterminate={table.getIsSomeRowsSelected()}
                                indeterminate={table.getIsSomePageRowsSelected()}
                                // onChange={table.getToggleAllRowsSelectedHandler()}
                                onChange={table.getToggleAllPageRowsSelectedHandler()}
                            />
                            <PerformanceIconButton
                                icon='filter_list_off'
                                disabled={!anyColFiltered}
                                onClick={() => {
                                    setDefaultPreset(undefined);
                                    table.resetColumnFilters(true);
                                }}
                            />
                        </div>
                    )
                },
                cell: (info) => {
                    const isEditDisabled = info.row.original.status === OrderStatus.Delivered;
                    const load = info.row.original;
                    const canUnmerge = hasUnmergeFeature && Boolean(load.mergedFromOrderIds) && load.isDraft;

                    return (
                        <>
                            <PerformanceCheckbox
                                checked={info.row.getIsSelected()}
                                onChange={info.row.getToggleSelectedHandler()}
                            />
                            <PerformanceIconButton
                                icon='edit'
                                disabled={isEditDisabled}
                                onClick={() => {
                                    handleEditableLoadOpen(load);
                                }}
                            />
                            <PerformanceIconButton
                                icon='public'
                                onClick={() => {
                                    handleDeliveryLogOpen(load);
                                }}
                            />
                            {canUnmerge && <PerformanceIconButton
                                icon='undo'
                                onClick={() => {
                                    handleUnmergeOpen(load);
                                }}
                            />}
                        </>
                    );
                },
            }),
            columnHelper.accessor((row) => {
                const orderNumbers = [...new Set(row.stops.map(s => s.orderNumber).filter(x => x !== null))];
                return orderNumbers;
            }, {
                id: ORDER_NUMBER_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTable2HeaderCell
                                header={header}
                                label={L.orderNumber()}
                            />
                            <TextFilter
                                column={header.column}
                                defaultPreset={defaultPreset}
                                setDefaultPreset={setDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const orderNumbers = info.getValue();
                    const load = info.row.original;
                    return (
                        <LoadOrderNumbers2
                            id={load.id}
                            orderNumbers={orderNumbers}
                        />
                    );
                },
                enableSorting: false,
                // filterFn: 'includesString',
                filterFn: (row: Row<Load>, columnId: string, filterValue: string) => {
                    const rowOrderNums = row.getValue<string[]>(columnId);
                    const someOrderNums = rowOrderNums.some(x => doesValueMatch(x, filterValue));
                    const loadIdMatch = doesValueMatch(row.original.id, filterValue);
                    return someOrderNums || loadIdMatch;
                },
            }),
            columnHelper.accessor((row) => {
                const originIds = row.stops.filter(filterStopByOrigin).map(x => x.organizationLocationId);
                return originIds;
            }, {
                id: ORIGIN_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTable2HeaderCell
                                header={header}
                                label={L.origin()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={organizationLocations}
                                displayFunc={toSiteDisplayNameFromOrganizationLocation}
                                defaultPreset={defaultPreset}
                                setDefaultPreset={setDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const originIds = info.getValue();
                    const uniqueOriginIds = [...new Set(originIds)];
                    const origins = info.row.original.stops.filter(filterStopByOrigin);

                    return uniqueOriginIds.map(uniqueOriginId => {
                        return (
                            <LoadStopLocation2
                                key={uniqueOriginId}
                                organizationLocationId={uniqueOriginId}
                                organizationLocations={organizationLocations}
                                stops={origins}
                            />
                        );
                    });
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStop(organizationLocations, 'Origin')(rowA.original, rowB.original);
                },
                filterFn: (row: Row<Load>, _: string, filterValue: OriginDestinationFilterValue) => {
                    const load = row.original;
                    if (filterValue.sublocationId) {
                        const sub = filterValue.sublocationId;
                        return load.stops.some(stop => stop.type === StopType.Origin && stop.organizationSubLocationIds?.includes(sub));
                    }
                    return load.stops.some(stop => filterValue.value.includes(stop.organizationLocationId));
                },
            }),
            columnHelper.accessor((row) => {
                const destinationIds = row.stops.filter(filterStopByDestination).map(x => x.organizationLocationId);
                return destinationIds;
            }, {
                id: DESTINATION_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTable2HeaderCell
                                header={header}
                                label={L.destination()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={organizationLocations}
                                displayFunc={toSiteDisplayNameFromOrganizationLocation}
                                defaultPreset={defaultPreset}
                                setDefaultPreset={setDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const destinationIds = info.getValue();
                    const uniqueDestinationIds = [...new Set(destinationIds)];
                    const destinations = info.row.original.stops.filter(filterStopByDestination);

                    return uniqueDestinationIds.map(uniqueDestinationId => {
                        return (
                            <LoadStopLocation2
                                key={uniqueDestinationId}
                                organizationLocationId={uniqueDestinationId}
                                organizationLocations={organizationLocations}
                                stops={destinations}
                            />
                        );
                    });
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStop(organizationLocations, 'Destination')(rowA.original, rowB.original);
                },
                filterFn: (row: Row<Load>, _: string, filterValue: OriginDestinationFilterValue) => {
                    const load = row.original;
                    if (filterValue.sublocationId) {
                        const sub = filterValue.sublocationId;
                        return load.stops.some(stop => stop.type === StopType.Destination && stop.organizationSubLocationIds?.includes(sub));
                    }
                    return load.stops.some(stop => filterValue.value.includes(stop.organizationLocationId));
                },
            }),
            columnHelper.accessor((row) => {
                const firstStop = loadToFirstStop(row);
                return firstStop.arrivalTime as string | null;
            }, {
                id: FIRST_STOP_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTable2HeaderCell
                                header={header}
                                label={L.firstStop()}
                            />
                            <TextFilter
                                column={header.column}
                                defaultPreset={defaultPreset}
                                setDefaultPreset={setDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    return <LoadStopTime2 time={info.getValue()} />;
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStopTime(loadToFirstStop)(rowA.original, rowB.original);
                },
                filterFn: 'includesString',
            }),
            columnHelper.accessor((row) => {
                const lastStop = loadToLastStop(row);
                return lastStop.arrivalTime as string | null;
            }, {
                id: LAST_STOP_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTable2HeaderCell
                                header={header}
                                label={L.lastStop()}
                            />
                            <TextFilter
                                column={header.column}
                                defaultPreset={defaultPreset}
                                setDefaultPreset={setDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    return <LoadStopTime2 time={info.getValue()} />;
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStopTime(loadToLastStop)(rowA.original, rowB.original);
                },
                filterFn: 'includesString',
            }),
            ...(!isDrafts ? [columnHelper.accessor((row) => {
                const lastCompletedStop = orderToLastCompleted(row);
                if (lastCompletedStop) {
                    return lastCompletedStop.completedAt as string | null;
                }
                return null;
            }, {
                id: COMPLETED_AT_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTable2HeaderCell
                                header={header}
                                label={L.completed()}
                            />
                            <TextFilter
                                column={header.column}
                                defaultPreset={defaultPreset}
                                setDefaultPreset={setDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    return <LoadStopTime2 time={info.getValue()} />;
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStopCompletedTime(orderToLastCompleted)(rowA.original, rowB.original);
                },
                filterFn: 'includesString',
            })] : []),
            ...(showEstimatedTotalTripTime ? [columnHelper.display({
                id: TRIP_TIME_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <LoadTable2HeaderCell
                            header={header}
                            label={L.estimatedTotalTripTime()}
                        />
                    );
                },
                cell: (info) => {
                    const load = info.row.original;
                    const loadDurationMinutes = orderToLoadingDurationMinutes(load);
                    const { travelTime, travelDistance, isLoading } = useTravelTimesLoad(load);
                    const minuteMs = 1000 * 60;
                    const roundedTotalTripMs = Math.ceil((travelTime + loadDurationMinutes) / 5) * 5 * minuteMs; // move to next 5 minute interval
                    const newText = roundedTotalTripMs > 0 ? getDuration(roundedTotalTripMs) : '';

                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {isLoading
                                ? <CircularProgress size='1em' />
                                : <AgisticsTooltip
                                        value={newText}
                                    >
                                        <NoMUITravelTimeTooltip
                                            loadDurationMinutes={loadDurationMinutes}
                                            // convertedDistance={convertedDistance}
                                            // isMetric={isMetric}
                                            travelTimeMinutes={travelTime}
                                            travelDistance={travelDistance}
                                        />
                                    </AgisticsTooltip>

                            }
                        </div>
                    );
                },
            })] : []),
            columnHelper.accessor((row) => {
                const cargoTypeIds = row.stops.map(x => x.cargoTypeId).filter(x => x !== null);
                const uniqueCargoTypeIds = [...new Set(cargoTypeIds)];
                return uniqueCargoTypeIds;
            }, {
                id: CARGO_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTable2HeaderCell
                                header={header}
                                label={L.cargo()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={cargoTypeList}
                                displayFunc={(c: CargoType) => {
                                    return c.label;
                                }}
                                defaultPreset={defaultPreset}
                                setDefaultPreset={setDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const cargoQuantities = orderToCargoQuantities(info.row.original, convertFromGramsDisplay);

                    return (
                        <div>
                            {Object.entries(cargoQuantities).map(([cargo, quantity]) => {
                                return (
                                    <div key={cargo}>
                                        {quantity ? `${quantity} - ${cargo}` : `${cargo}`}
                                    </div>
                                );
                            })}
                            {/* <SalmonellaMeter severity={load.metadata?.cargo?.salmonellaResult} /> */}
                        </div>
                    );
                },
                enableSorting: false,
                filterFn: (row: Row<Load>, columnId: string, filterValue: number[]) => {
                    const rowCargoTypeIds = row.getValue(columnId) as number[];
                    return rowCargoTypeIds.some(r => filterValue.includes(r));
                },
            }),
            columnHelper.accessor('transportedByUserId', {
                id: DRIVER_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTable2HeaderCell
                                header={header}
                                label={L.driver()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={drivers}
                                displayFunc={(d: Driver) => {
                                    return d.name;
                                }}
                                defaultPreset={defaultPreset}
                                setDefaultPreset={setDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowExpanded = info.row.getIsExpanded();
                    const isRowEditable = draftsLoadsEditable || isRowExpanded;

                    if (isRowEditable) {
                        const { editedLoad, onEditedLoadChanged } = useDraftLoadContext();

                        return (
                            <AgisticsSelect
                                item={editedLoad.transportedByUserId}
                                items={loadsEditableItems.driverMultiItems}
                                onChange={(e) => {
                                    onEditedLoadChanged({ transportedByUserId: e });
                                }}
                            />
                        );
                    }

                    return toDriverDisplayName(drivers)(info.row.original);
                },
                sortingFn: (rowA, rowB) => {
                    return sortByDriver(drivers)(rowA.original, rowB.original);
                },
                filterFn: (row: Row<Load>, columnId: string, filterValue: number[]) => {
                    const rowDriverId = row.getValue(columnId) as number | null;
                    return rowDriverId ? filterValue.includes(rowDriverId) : false;
                },
            }),
            columnHelper.accessor('tractorId', {
                id: TRACTOR_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTable2HeaderCell
                                header={header}
                                label={L.tractor()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={tractors}
                                displayFunc={(t: Tractor) => {
                                    return t.userDisplayName;
                                }}
                                defaultPreset={defaultPreset}
                                setDefaultPreset={setDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowExpanded = info.row.getIsExpanded();
                    const isRowEditable = draftsLoadsEditable || isRowExpanded;

                    if (isRowEditable) {
                        const { editedLoad, onEditedLoadChanged } = useDraftLoadContext();

                        return (
                            <AgisticsSelect
                                item={editedLoad.tractorId}
                                items={loadsEditableItems.tractorMultiItems}
                                onChange={(e) => {
                                    onEditedLoadChanged({ tractorId: e });
                                }}
                            />
                        );
                    }

                    return toTractorDisplayName(tractors)(info.row.original);
                },
                sortingFn: (rowA, rowB) => {
                    return sortByTractor(tractors)(rowA.original, rowB.original);
                },
                filterFn: (row: Row<Load>, columnId: string, filterValue: number[]) => {
                    const rowTractorId = row.getValue(columnId) as number | null;
                    return rowTractorId ? filterValue.includes(rowTractorId) : false;
                },
            }),
            columnHelper.accessor('trailerId', {
                id: TRAILER_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTable2HeaderCell
                                header={header}
                                label={L.trailer()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={trailers}
                                displayFunc={(t: Trailer) => {
                                    return t.userDisplayName;
                                }}
                                defaultPreset={defaultPreset}
                                setDefaultPreset={setDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowExpanded = info.row.getIsExpanded();
                    const isRowEditable = draftsLoadsEditable || isRowExpanded;

                    if (isRowEditable) {
                        const { editedLoad, onEditedLoadChanged } = useDraftLoadContext();

                        return (
                            <AgisticsSelect
                                item={editedLoad.trailerId}
                                items={loadsEditableItems.trailerMultiItems}
                                onChange={(e) => {
                                    onEditedLoadChanged({ trailerId: e });
                                }}
                            />
                        );
                    }

                    return toTrailerDisplayName(trailers)(info.row.original);
                },
                sortingFn: (rowA, rowB) => {
                    return sortByTrailer(trailers)(rowA.original, rowB.original);
                },
                filterFn: (row: Row<Load>, columnId: string, filterValue: number[]) => {
                    const rowTrailerId = row.getValue(columnId) as number | null;
                    return rowTrailerId ? filterValue.includes(rowTrailerId) : false;
                },
            }),
            columnHelper.accessor('status', {
                id: STATUS_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTable2HeaderCell
                                header={header}
                                label={L.status()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={orderStatuses.map(x => (
                                    {
                                        id: x.key,
                                        label: x.label,
                                    }
                                ))}
                                displayFunc={(o: any) => {
                                    return o.label;
                                }}
                                defaultPreset={defaultPreset}
                                setDefaultPreset={setDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const load = info.row.original;
                    const isCarrier = load.organizationId !== load.transportingOrganizationId;
                    if (isCarrier) {
                        const carrierName = carriers.find(x => x.carrierOrganizationId === load.transportingOrganizationId)?.carrier?.name;
                        return <div title={carrierName} style={{ color: '#29a84a', fontWeight: 'bold' }}>{ORDER_STATUSES()[load.status].label}</div>
                    }
                    return ORDER_STATUSES()[info.getValue()].label;
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStatus()(rowA.original, rowB.original);
                },
                filterFn: (row: Row<Load>, columnId: string, filterValue: OrderStatusFromTypes[]) => {
                    const rowStatus = row.getValue(columnId) as OrderStatusFromTypes;
                    return filterValue.includes(rowStatus);
                },
            }),
            columnHelper.display({
                id: 'expander',
                cell: (info) => {
                    const load = info.row.original;

                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {load.isDraft && <DraftConflictIcon draft={load} convertFromGrams={convertFromGrams} />}
                            <PerformanceExpander
                                expanded={info.row.getIsExpanded()}
                                onChange={() => {
                                    info.row.toggleExpanded();
                                }}
                            />
                        </div>
                    )
                },
            }),
        ];

        return c;
    }, [isDrafts, organizationLocations, drivers, tractors, trailers, cargoTypeList, showEstimatedTotalTripTime, defaultPreset, draftsLoadsEditable]);

    return columns;
}
