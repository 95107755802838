import React, { useState } from 'react';
import { Autocomplete, AutocompleteRenderGetTagProps, Checkbox, TextField } from '@mui/material';
import { L } from 'harmony-language';
import { CustomPopper } from './custom-popper';
import { AgisticsMultiSelectChildren } from './agistics-multi-select-children';

export interface AgisticsMultiItem<T> {
    id: T;
    value: string;
    children?: AgisticsMultiItem<T>[];
}

interface AgisticsMultiSelectProps<T> {
    selectedIds: T[];
    setSelectedIds: (s: T[], a?: T) => void;
    items: AgisticsMultiItem<T>[];
    label?: string;
    presistPrefsChange?: (v: T[]) => void;
    confinedSpace?: boolean;
    disabled?: boolean;
    // need to rework this confinedSpace & applyCustomPopper when inline edit stuff is removed
    applyCustomPopper?: boolean;
    inputText?: string;
}

/**
 * {@link items} prop must be checked as !undefined before rendering this component
 */
export const AgisticsMultiSelect = <T extends string | number>(props: AgisticsMultiSelectProps<T>) => {
    const { selectedIds, setSelectedIds, items, label, presistPrefsChange, confinedSpace, disabled, applyCustomPopper, inputText } = props;
    const selectedValues = React.useMemo(() => items.filter(x => selectedIds.includes(x.id)), [selectedIds]);

    const renderSelected = (selected: AgisticsMultiItem<T>[], getTagProps: AutocompleteRenderGetTagProps) => {
        const tagProps = getTagProps({ index: 1 });

        if (confinedSpace) {
            return <div {...tagProps} style={{ fontSize: '.875rem' }}>{`[${selected.length.toString()}]`}&nbsp;</div>
        } else if (applyCustomPopper) {
            const inputString = inputText ? ' ' + inputText : '';
            return <div {...tagProps}>{`[${selected.length.toString()}]${inputString}`}&nbsp;</div>
        } else {
            return <div {...tagProps}>{L.xSelected(selected.length.toString())}&nbsp;</div>
        }
    };

    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            disableClearable={confinedSpace}
            disabled={disabled}
            options={items}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedValues}
            onChange={(event, value) => {
                if (event.type !== 'keydown' && !event.isDefaultPrevented()) {
                    const mappedVal = value.map(x => x.id);
                    setSelectedIds(mappedVal);
                    presistPrefsChange && presistPrefsChange(mappedVal);
                }
            }}
            PopperComponent={(confinedSpace || applyCustomPopper ) ? CustomPopper : undefined}
            sx={confinedSpace ? { input: { fontSize: '.875rem' }} : undefined}
            renderInput={(params) => {
                return <>
                    <TextField
                        {...params}
                        variant='standard'
                        label={label}
                    />
                </>
            }}
            renderTags={renderSelected}
            renderOption={(props, option) => {
                return (
                    <li
                        {...props}
                        // props passes a key that is value, which might not be unique (users/contacts with same name)
                        key={option.id}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                    >
                        <div>
                            <Checkbox checked={selectedIds.indexOf(option.id) > -1} style={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }} />
                            {option.value}
                        </div>
                        {option.children && <AgisticsMultiSelectChildren
                            selectedValues={selectedValues}
                            option={option}
                            setSelectedIds={setSelectedIds}
                        >
                            {option.children}
                        </AgisticsMultiSelectChildren>}
                    </li>
                )
            }}
        />
    );
};
