import React from 'react';
import { Stop } from '../../../../types';
import { AgisticsDateTimePicker } from '../../../shared/inputs/agistics-date-time-picker';
import { useDraftLoadStopContext } from '../../hooks/draft-load-stop-context';
import { Validation } from '../../../shared/inputs/validated-text-field';

interface EditableStopTimeProps {
    stop: Stop;
    property: 'readyTime' | 'arrivalTime' | 'requiredBegin' | 'requiredEnd';
    // validations?: Validation[];
}

export const EditableStopTime: React.FC<EditableStopTimeProps> = (props) => {
    const { stop, property } = props;
    const { onEditedStopChanged } = useDraftLoadStopContext();
    const dateTime = stop[property];

    return (
        <AgisticsDateTimePicker
            value={dateTime}
            // referenceDate={stop.arrivalTime}
            // required={required || false}
            // disabled={isSaving || stopEditDisabled}
            onChange={(dt: string | null) => onEditedStopChanged({ [property]: dt || null })}
            // validations={validations}
            smallText={true}
        />
    );
};
