import { Table } from '@tanstack/react-table';
import React from 'react';
import { Load } from '../../types';
import { LoadTable2Header } from './table-components/load-table2-header';
import { LoadTable2Body } from './table-components/load-table2-body';
import { AgisticsTablePaging } from '../shared/table/components/agistics-table-paging';
import { CircularLoading } from '../shared/circular-loading';
import { useUpdateUserPreferences } from '../shared/hooks/use-update-user-preferences';

interface LoadTable2Props {
    isDrafts: boolean;
    isLoading: boolean;
    table: Table<Load>;
}

export const LoadTable2: React.FC<LoadTable2Props> = (props) => {
    const { isDrafts, isLoading, table } = props;
    const { preferences, updatePreferences } = useUpdateUserPreferences();

    if (isLoading) {
        return <CircularLoading />
    }

    const setPageSizePreferences = (pageSize: number) => {
        const preferencesKey = isDrafts ? 'drafts' : 'loads';

        const newPreferences = {
            ...preferences,
            tables: {
                ...preferences.tables,
                [preferencesKey]: {
                    pageSize: pageSize,
                }
            }
        }

        updatePreferences(newPreferences);
    }

    return (
        <>
            <AgisticsTablePaging
                table={table}
                setPageSizePreferences={setPageSizePreferences}
            />
            <div className='lt-base'>
                <LoadTable2Header
                    isDrafts={isDrafts}
                    table={table}
                    showEstimatedTotalTripTime={preferences.showEstimatedTotalTripTime || false}
                />
                <LoadTable2Body
                    isDrafts={isDrafts}
                    table={table}
                    showEstimatedTotalTripTime={preferences.showEstimatedTotalTripTime || false}
                />
            </div>
        </>
    );
};
