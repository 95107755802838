import { TextField } from '@mui/material';
import { Column } from '@tanstack/react-table';
import React, { useEffect } from 'react';
import { Load, Preset2 } from '../../../../types';
import { COMPLETED_AT_COLUMN, FIRST_STOP_COLUMN, LAST_STOP_COLUMN, ORDER_NUMBER_COLUMN } from '../../use-loads2-columns';

interface TextFilterProps {
    column: Column<Load, unknown>;
    defaultPreset?: Preset2;
    setDefaultPreset: (u: undefined) => void;
}

export const TextFilter: React.FC<TextFilterProps> = (props) => {
    const { column, defaultPreset, setDefaultPreset } = props;
    const isDate = column.id === FIRST_STOP_COLUMN || column.id === LAST_STOP_COLUMN || column.id === COMPLETED_AT_COLUMN;
    const columnDefaultPreset = defaultPreset?.filters.find(x => x.field === column.id);

    useEffect(() => {
        if (columnDefaultPreset) {
            column.setFilterValue(columnDefaultPreset.value);
        }
    }, [columnDefaultPreset]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        defaultPreset && setDefaultPreset(undefined);
        column.setFilterValue(e.target.value);
    }

    return (
        <TextField
            variant='standard'
            value={column.getFilterValue() || ''}
            onChange={handleChange}
            placeholder={isDate ? 'YYYY-MM-DD' : undefined}
        />
    );
};
