import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { Load } from '../../../types';
import { useLoadsKey } from '../../config-hooks';

const addLoad = async (options: { load: Load }) => {
    const load = {
        ...options.load,
        date: options.load?.stops?.[0].arrivalTime
    };

    return (await axios.post(`${API_BASE}/api/orders`, load)).data;
}

export const useAddLoadMutation = () => {
    const snackbar = useSnackbar();
    const loadsKey = useLoadsKey();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: addLoad,
        onSuccess: (data) => {
            //map organizationId to owningOrganizationId as web project only knows organizationId
            data.organizationId = data.owningOrganizationId;
            const previousCache = queryClient.getQueryData<Load[]>(loadsKey);

            if (previousCache) {
                const newLoads = previousCache.concat(data);
                queryClient.setQueryData(loadsKey, newLoads);
                snackbar(L.loadAddedSuccessfully());
            }
        },
        onSettled: () => {}
    });

    return mutation;
};
