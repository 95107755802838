import React from 'react';

// https://developers.google.com/fonts/docs/material_symbols
// https://fonts.google.com/icons

interface PerformanceIcon {
    icon: string;
}

export const PerformanceIcon: React.FC<PerformanceIcon> = (props) => {
    const { icon } = props;

    return (
        <span
            className='material-icons lt-button-color size-16'
            style={{ cursor: 'default' }}
        >
            {icon}
        </span>
    );
}

interface PerformanceIconButtonProps {
    icon: string;
    onClick: () => void;
    disabled?: boolean;
}

export const PerformanceIconButton: React.FC<PerformanceIconButtonProps> = (props) => {
    const { icon, onClick, disabled = false } = props;

    return (
        <button
            className={`lt-button ${!disabled && 'cursor-pointer lt-button-color'}`}
            onClick={onClick}
            disabled={disabled}
        >
            <span className='material-icons size-24'>{icon}</span>
        </button>
    );
};
