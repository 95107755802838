import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Load } from '../../../types';
import { deepEqual } from '../../../utils/deep-equal';
import { useEditLoadMutation } from '../../../api/mutations/edit/use-edit-load-mutation';
import { useDraftsKey, useLoadsKey } from '../../../api/config-hooks';

interface DraftLoadContext {
    editedLoad: Load;
    onEditedLoadChanged: (value: Partial<Load>) => void;
    isDirty: boolean;
    saveEditedLoad: () => void;
}

const DraftLoadContext = createContext<DraftLoadContext | undefined>(undefined);

interface DraftLoadProviderProps {
    isDrafts: boolean;
    inputLoad: Load;
    children: ReactNode;
}

// overtime LoadProvider got very bloated causing all sorts of performace problems
// which is 'fine' when only 1 LoadProvider is on the page, but when needing to load up 100+
// starts to cause problems
// DraftLoadProvider is an attempt to only create what is needed for inline editing
export const DraftLoadProvider: FC<DraftLoadProviderProps> = (props) => {
    const { isDrafts, inputLoad, children } = props;
    const [editedLoad, setEditedLoad] = useState(inputLoad);
    const [isDirty, setIsDirty] = React.useState(false);

    useEffect(() => {
        setIsDirty(!deepEqual(inputLoad, editedLoad));
    }, [inputLoad, editedLoad]);

    const queryKey = isDrafts ? useDraftsKey() : useLoadsKey();
    const { mutate: editLoad, isLoading: isEditingLoad } = useEditLoadMutation(queryKey);

    // TODO: oder.date...
    const onEditedLoadChanged = (value: Partial<Load>) => {
        const newLoad = {
            ...editedLoad,
            ...value,
        }

        setEditedLoad(newLoad);

        // setIsDirty(!deepEqual(inputLoad, newLoad));
    }

    const saveEditedLoad = () => {
        editLoad(editedLoad);
    }

    return (
        <DraftLoadContext.Provider
            value={{
                editedLoad,
                onEditedLoadChanged,
                isDirty,
                saveEditedLoad,
            }}
        >
            {children}
        </DraftLoadContext.Provider>
    );
}

export const useDraftLoadContext = () => {
    const draftLoadContext = useContext(DraftLoadContext);

    if (draftLoadContext === undefined) {
        throw new Error('useDraftLoadContext must be used within a DraftLoadProvider');
    }

    return draftLoadContext;
}
