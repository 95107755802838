import { Row } from '@tanstack/react-table'
import { Load } from '../../../../types'
import { useWeights } from '../../../shared/hooks/use-weights';
import { filterStopByDestination } from '../../../../utils/data-mapping-utils';
import { Comparison, ComparisonFilter } from './comparison-filter';
import { searchByDriver, searchByLoadId, searchByOrderNumber, searchByStopCargo, searchByStopCustomerOrderNumber, searchByStopDates, searchByStopLoadingTeam, searchByStopLocation, searchByTractor, searchByTrailer } from '../../../shared/table/utils/search-functions';
import { useTractorTrailer } from '../../../../api/queries/use-tractor-trailer';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../../api/config';

export interface GlobalFilterValue {
    comparison?: ComparisonFilter;
    // careful - this is actually an array organizationLocationIds not customerIds
    // like this because we only have access to the load in the global filter function and it's faster
    // update - the access part used to be true, but have reworked it since and not true anymore
    customer?: number[];
    arbitraryText?: string;
}

export const useLoadsGlobalFilter = () => {
    const { convertFromGrams } = useWeights();
    const { tractors, trailers , isLoadingTractors, isLoadingTrailers } = useTractorTrailer();
    const { data: loadingTeams, isLoading: isLoadingLoadingTeams } = useOrganizationQuery(OrgQueryKeys.loadingTeams);
    const { data: organizationLocations, isLoading: isLoadingLocations } = useOrganizationQuery(OrgQueryKeys.locations);
    const { data: drivers, isLoading: isLoadingDrivers } = useOrganizationQuery(OrgQueryKeys.drivers);
    const isLoading = isLoadingTractors || isLoadingTrailers || isLoadingLoadingTeams || isLoadingLocations || isLoadingDrivers;

    return (row: Row<Load>, _: string, filterValue: GlobalFilterValue) => {
        const load = row.original;

        const comparisonResult = () => {
            if (filterValue.comparison) {
                const filter = filterValue.comparison;
                const operator = filter.category;
                const destinationsWithWeight = load.stops.filter(stop => filterStopByDestination(stop) && stop.weight !== null);

                if (destinationsWithWeight.length === 0) {
                    return false;
                } else {
                    const loadDestinationWeightTotal = destinationsWithWeight
                        .map(stop => stop.weight || 0)
                        .reduce((acc, stopWeight) => acc + stopWeight);

                    const totalWeightForCompare = convertFromGrams(loadDestinationWeightTotal);

                    if (operator === Comparison.LessThan) {
                        return filter.value > totalWeightForCompare;
                    } else if (operator === Comparison.LessThanEqual) {
                        return filter.value >= totalWeightForCompare;
                    } else if (operator === Comparison.MoreThan) {
                        return filter.value < totalWeightForCompare;
                    } else if (operator === Comparison.MoreThanEqual) {
                        return filter.value <= totalWeightForCompare;
                    } else if (operator === Comparison.Equal) {
                        return filter.value === totalWeightForCompare;
                    }
                }
            } else {
                return true;
            }
        }

        const customerResult = () => {
            if (filterValue.customer) {
                const filter = filterValue.customer;
                const has = load.stops.some(x => filter.includes(x.organizationLocationId));
                return has;
            } else {
                return true;
            }
        }

        const arbitraryTextResult = () => {
            if (filterValue.arbitraryText && !isLoading) {
                const filter = filterValue.arbitraryText;
                const hasDriver = searchByDriver(load, filter, drivers);
                const hasTractor = searchByTractor(load, filter, tractors);
                const hasTrailer = searchByTrailer(load, filter, trailers);

                const hasCargo = searchByStopCargo(load, filter);
                const hasOrderNumber = searchByOrderNumber(load, filter);
                const hasLocation = searchByStopLocation(load, filter, organizationLocations);
                const hasLoadingTeam = searchByStopLoadingTeam(load, filter, loadingTeams);
                const hasCustomerOrderNumber = searchByStopCustomerOrderNumber(load, filter);
                const hasDate = searchByStopDates(load, filter);
                const hasId = searchByLoadId(load, filter);

                return hasDriver || hasTractor || hasTrailer || hasCargo || hasOrderNumber || hasLocation || hasLoadingTeam || hasCustomerOrderNumber || hasDate || hasId;
            } else {
                return true;
            }
        }

        return comparisonResult() && customerResult() && arbitraryTextResult();
    }
}
