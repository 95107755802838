import React from 'react';

interface PerformanceExpanderProps {
    expanded: boolean;
    onChange: (e: unknown) => void;
    title?: string;
}

export const PerformanceExpander: React.FC<PerformanceExpanderProps> = (props) => {
    const { expanded, onChange, title } = props;

    return (
        <button
            title={title}
            className='lt-button cursor-pointer lt-button-expander-color'
            onClick={onChange}
        >
            {expanded ? <span className='material-icons size-24'>keyboard_arrow_up</span> : <span className='material-icons size-24'>keyboard_arrow_down</span>}
        </button>
    );
};
