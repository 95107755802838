import React, { useState } from 'react';
import { Stop } from '../../../types';
import { StopCargoType2, StopCompartments2, StopLocation2, StopNotes2, StopQuantity2, StopText2, StopTime2, StopType2, StopWeight2 } from '../loads2-table-cells/stop-table-cells2';
import { PerformanceExpander } from '../loads2-table-cells/performance-expander';
import { L } from 'harmony-language';
import { DraftLoadStopProvider } from '../hooks/draft-load-stop-context';
import { EditableStopLocation } from '../loads2-table-cells/stops/editable-stop-location';
import { EditableStopSublocations } from '../loads2-table-cells/stops/editable-stop-sublocations2';
import { EditableStopTime } from '../loads2-table-cells/stops/editable-stop-time';
import { stopGridTemplateColumnsGenerator } from '../utils';
import { EditableStopQuantity } from '../loads2-table-cells/stops/editable-stop-quantity';
import { EditableStopWeight } from '../loads2-table-cells/stops/editable-stop-weight';
import { EditableStopCargoType } from '../loads2-table-cells/stops/editable-stop-cargo-type';
import { EditableStopText } from '../loads2-table-cells/stops/editable-stop-text';

export const editableStopInputProps = { style: { fontSize: '.875rem' } };

interface LoadTable2StopRowProps {
    isDrafts: boolean;
    stop: Stop;
    trailerId: number | null; // compartments needs this passed down
}

export const LoadTable2StopRow: React.FC<LoadTable2StopRowProps> = (props) => {
    const { isDrafts, stop, trailerId } = props;
    const [secondRowVis, setSecondRowVis] = useState(false);

    return (
        <DraftLoadStopProvider inputStop={stop}>
            <div
                key={stop.id}
                className='lt-row-stop lt-row-stop-item'
                style={{ gridTemplateColumns: stopGridTemplateColumnsGenerator(isDrafts) }}
            >
                <div className='lt-cell lt-cell-sub'>
                    <StopType2 stop={stop} />
                </div>
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopLocation stop={stop} />
                </div>
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopSublocations stop={stop} />
                </div>

                <div className='lt-cell lt-cell-sub'>
                    <EditableStopTime
                        stop={stop}
                        property='readyTime'
                    />
                </div>
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopTime
                        stop={stop}
                        property='arrivalTime'
                    />
                </div>
                {isDrafts ? null : <div className='lt-cell lt-cell-sub'><StopTime2 dateTime={stop.completedAt} /></div>}

                {/* QUANTITY */}
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopQuantity stop={stop} />
                </div>
                {isDrafts ? null : <div className='lt-cell lt-cell-sub'><StopQuantity2 quantity={stop.actualQuantity} /></div>}

                {/* WEIGHT */}
                <div className='lt-cell lt-cell-sub'>
                    {/* <StopWeight2 weight={stop.weight} /> */}
                    <EditableStopWeight stop={stop} />
                </div>
                {isDrafts ? null : <div className='lt-cell lt-cell-sub'><StopWeight2 weight={stop.actualWeight} /></div>}

                <div className='lt-cell lt-cell-sub'>
                    {/* <StopCargoType2 stop={stop} /> */}
                    <EditableStopCargoType stop={stop} />
                </div>

                <div className='lt-cell lt-cell-sub'>
                    <EditableStopText stop={stop} property='orderNumber' />
                </div>
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopText stop={stop} property='productionPlan' />
                </div>
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopText stop={stop} property='lotId' />
                </div>

                <div className='lt-cell lt-cell-sub'><StopNotes2 stop={stop} /></div>

                <PerformanceExpander
                    title={stop.id.toString()}
                    expanded={secondRowVis}
                    onChange={() => {
                        setSecondRowVis(!secondRowVis);
                    }}
                />
            </div>
            {secondRowVis ? <>
                <div className='lt-row-stop-hidden lt-row-stop-header'>
                    <div>{L.customerOrderNumber()}</div>
                    <div>{L.requiredBegin()}</div>
                    <div>{L.requiredEnd()}</div>
                    <div>{L.compartments()}</div>
                </div>
                <div className='lt-row-stop-hidden'>
                    <div className='lt-cell lt-cell-sub'>
                        <EditableStopText stop={stop} property='customerOrderNumber' />
                    </div>
                    <div className='lt-cell lt-cell-sub'>
                        {/* <StopTime2 dateTime={stop.requiredBegin} /> */}
                        <EditableStopTime
                            stop={stop}
                            property='requiredBegin'
                        />
                    </div>
                    <div className='lt-cell lt-cell-sub'>
                        {/* <StopTime2 dateTime={stop.requiredEnd} /> */}
                        <EditableStopTime
                            stop={stop}
                            property='requiredEnd'
                        />
                    </div>
                    <div className='lt-cell lt-cell-sub'><StopCompartments2 stop={stop} trailerId={trailerId} /></div>
                </div>
            </> : null}
        </DraftLoadStopProvider>
    );
};
