import { Column } from '@tanstack/react-table';
import React, { useEffect, useMemo } from 'react';
import { CargoType, Driver, Load, OrganizationLocation, Preset2, Tractor, Trailer } from '../../../../types';
import { AgisticsMultiSelect } from '../../../shared/multi-select/agistics-multi-select';
import { CARGO_COLUMN, DESTINATION_COLUMN, DRIVER_COLUMN, ORIGIN_COLUMN, OriginDestinationFilterValue, STATUS_COLUMN, TRACTOR_COLUMN, TRAILER_COLUMN } from '../../use-loads2-columns';

interface MultiFilterProps<T> {
    column: Column<Load, unknown>;
    baseItems: T[];
    displayFunc: (t: T) => string;
    defaultPreset?: Preset2;
    setDefaultPreset: (u: undefined) => void;
}

type FilterItems = OrganizationLocation | Driver | Tractor | Trailer | CargoType;

// https://tanstack.com/table/v8/docs/guide/column-faceting
export const MultiFilter = <T extends FilterItems>(props: MultiFilterProps<T>) => {
    const { column, baseItems, displayFunc, defaultPreset, setDefaultPreset } = props;
    const facetedKeys = Array.from(column.getFacetedUniqueValues().keys()).filter(x => x !== null).flat();
    const columnDefaultPreset = defaultPreset?.filters.find(x => x.field === column.id);

    useEffect(() => {
        if (columnDefaultPreset) {
            column.setFilterValue(columnDefaultPreset.value);
        }
    }, [columnDefaultPreset]);

    const items = useMemo(() => {
        const flattenedUniqueFacetedKeys = [...new Set(facetedKeys.map(x => x))];

        const uniqueItems = flattenedUniqueFacetedKeys.map(x => {
            const foundItem = baseItems.find(baseItem => baseItem.id === x);
            if (foundItem) {
                return foundItem;
            }
        }).filter(x => !!x);

        const items = uniqueItems.map(x => {
            return {
                id: x.id,
                value: displayFunc(x),
                children: 'orgSubLocations' in x ? x.orgSubLocations.map(sublocation => {
                    return {
                        id: sublocation.id,
                        value: sublocation.name,
                    }
                }) : undefined,
            }
        });

        // faceted logic messes up sort
        const sorted = items.toSorted((a, b) => a.value.localeCompare(b.value));
        return sorted;
    }, [facetedKeys]);

    // if not setting to undefined, need to check length in filterFn
    const handleChange = (value: number[], sublocationId?: number) => {
        defaultPreset && setDefaultPreset(undefined);

        if (value.length && column.id !== ORIGIN_COLUMN && column.id !== DESTINATION_COLUMN) {
            column.setFilterValue(value);
        } else if (value.length) {
            const originDestFilter: OriginDestinationFilterValue = {
                value: value,
                sublocationId: sublocationId,
            }

            column.setFilterValue(originDestFilter);
        } else {
            column.setFilterValue(undefined);
        }
    }

    const forceTypedFilterValue = () => {
        if (columnDefaultPreset) {
            if (columnDefaultPreset.field === ORIGIN_COLUMN || columnDefaultPreset.field === DESTINATION_COLUMN) {
                return columnDefaultPreset.value.value;
            } else if (columnDefaultPreset.field === CARGO_COLUMN
                || columnDefaultPreset.field === DRIVER_COLUMN
                || columnDefaultPreset.field === TRACTOR_COLUMN
                || columnDefaultPreset.field === TRAILER_COLUMN
                || columnDefaultPreset.field === STATUS_COLUMN
            ) {
                return columnDefaultPreset.value;
            }
        }

        if (column.id === ORIGIN_COLUMN || column.id === DESTINATION_COLUMN) {
            const originDestFilter = column.getFilterValue() as { value: number[], sublocationId: number | undefined } | undefined;
            return originDestFilter?.value;
        }
        return column.getFilterValue() as number[] | undefined;
    }

    // doing this out here, to support preset sublocations
    // and an attempt to keep this logic out of AgisticsMultiSelect
    const getIntputText = () => {
        if (column.id === ORIGIN_COLUMN || column.id === DESTINATION_COLUMN) {
            const originDestFilter = column.getFilterValue() as OriginDestinationFilterValue | undefined;
            if (originDestFilter?.sublocationId) {
                const sublocationChildren = items.flatMap(item => item.children).filter(x => !!x);
                const foundSublocation = sublocationChildren.find(x => x.id === originDestFilter.sublocationId);
                if (foundSublocation) {
                    return foundSublocation.value;
                }
            }
        }
    }

    return (
        <AgisticsMultiSelect
            items={items}
            selectedIds={forceTypedFilterValue() || []}
            setSelectedIds={handleChange}
            applyCustomPopper={true}
            inputText={getIntputText()}
        />
    )
};
