import React from 'react';

interface PerformanceCheckboxProps {
    checked: boolean;
    indeterminate?: boolean;
    onChange: (e: unknown) => void;
}

// MUI checkbox vs input vs (button/span)
// 150ms 300ms
//  70ms 170ms
//  70ms 170ms (input vs button/span is essentially the same)

// 1st number is to check the box
// 2nd number is sorting the column on 1st stop (600 records 100 on screen)
export const PerformanceCheckbox: React.FC<PerformanceCheckboxProps> = (props) => {
    const { checked, indeterminate, onChange } = props;

    return (
        <button
            className={`lt-button cursor-pointer ${(checked || indeterminate) && 'lt-button-color'}`}
            onClick={onChange}
        >
            {indeterminate
                ? <span className='material-icons size-24'>indeterminate_check_box</span>
                : checked
                ? <span className='material-icons size-24'>check_box</span>
                : <span className='material-icons size-24'>check_box_outline_blank</span>
            }
        </button>
    );
};
