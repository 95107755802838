import React from 'react';
import { useCargoTypes } from '../../../../api/queries/use-cargo-types';
import { stopHasCargo } from '../../../shared/load-table/stops-table-cells';
import { Stop } from '../../../../types';
import { AgisticsSelect } from '../../../shared/agistics-select';
import { useDraftLoadStopContext } from '../../hooks/draft-load-stop-context';

interface EditableStopCargoTypeProps {
    stop: Stop;
}

export const EditableStopCargoType: React.FC<EditableStopCargoTypeProps> = (props) => {
    const { stop } = props;
    const { onEditedStopChanged } = useDraftLoadStopContext();
    const { cargoTypeList, isLoading } = useCargoTypes();

    if (isLoading || !stopHasCargo(stop)) {
        return null;
    }

    return (
        <AgisticsSelect
            item={stop.cargoTypeId}
            items={cargoTypeList.map(x => ({ id: x.id, value: x.label }))}
            onChange={(id) => {
                onEditedStopChanged({ cargoTypeId: id });
            }}
        />
    );
};
