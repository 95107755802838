import React, { FC, useEffect, useState } from 'react';
import { ContentHeader } from '../shared/containers/content-header';
import { Button, Paper, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { Content } from '../shared/containers/content';
import { useCarrier, usePermissions } from '../user/selectors/use-permissions';
import { PermissionType } from 'harmony-constants';
import { useLoads2Columns } from './use-loads2-columns';
import { LoadTable2 } from './load-table2';
import { useTravelTimesQuery } from '../travel-times/use-travel-times';
import { LoadTableToolbar2 } from './load-table-toolbar2';
import { useLoadTable2 } from './use-load-table2';
import { Load } from '../../types';
import { useDraftsLoads } from '../../api/queries/use-drafts-loads';
import { Loads2Modals } from './loads2-modals';
import { L } from 'harmony-language';
import { useUser } from '../../api/queries/use-user';
import { getPageSize } from './utils';
import { SelectionButtons } from './selection-buttons';

interface Loads2Props {
    isDrafts: boolean;
}

export const Loads2: FC<Loads2Props> = (props) => {
    const { isDrafts } = props;

    const draftsLoadsQuery = useDraftsLoads(isDrafts);
    useTravelTimesQuery(draftsLoadsQuery.isFetching);

    const canCreateOrder = usePermissions(PermissionType.CreateOrder);
    const currentUserIsCarrierOrg = useCarrier();

    const [dialogOpen, setDialogOpen] = useState(false);

    const [editableLoad, setEditableLoad] = useState<Load | null>(null);
    const [delieryLogLoad, setDeliveryLogLoad] = useState<Load | undefined>(undefined);
    const [unmergeLoad, setUnmergeLoad] = useState<Load | null>(null);

    const [completableLoads, setCompletableLoads] = useState<Load[]>([]);
    const [assignableLoads, setAssignableLoads] = useState<Load[]>([]);
    const [carrierLoads, setCarrierLoads] = useState<Load[]>([]);
    const [deletableLoads, setDeletableLoads] = useState<Load[]>([]);
    const [bulkModifyRecords, setBulkModifyRecords] = useState<Load[]>([]);
    const [mergeRecords, setMergeRecords] = useState<Load[]>([]);
    const [cascadeRecords, setCascadeRecords] = useState<Load[]>([]);
    const [publishRecords, setPublishRecords] = useState<Load[]>([]);

    const handleEditableLoadOpen = (load: Load) => {
        setEditableLoad(load);
    }

    const handleDeliveryLogOpen = (load: Load) => {
        setDeliveryLogLoad(load);
    }

    const handleUnmergeOpen = (load: Load) => {
        setUnmergeLoad(load)
    }

    const columns = useLoads2Columns(isDrafts, handleEditableLoadOpen, handleDeliveryLogOpen, handleUnmergeOpen);
    const table = useLoadTable2(isDrafts, columns, draftsLoadsQuery.data || []);

    const { user } = useUser();

    const resetTableState = () => {
        table.setPagination({
            pageIndex: 0,
            pageSize: getPageSize(isDrafts, user),
        });
        table.resetRowSelection(true);
        table.resetColumnFilters(true);
        table.resetExpanded(true);
    }

    useEffect(() => {
        resetTableState();
    }, [isDrafts]);

    const isLoading = draftsLoadsQuery.isLoading;

    return (
        <>
            <ContentHeader>
                <div className='lt-header-container'>
                    <Typography variant='h5'>{isDrafts ? L.drafts() : L.loads()} - {user.organization.name}</Typography>
                    <SelectionButtons
                        isDrafts={isDrafts}
                        isLoading={isLoading}
                        table={table}
                        setCompletableLoads={setCompletableLoads}
                        setCarrierLoads={setCarrierLoads}
                        setAssignableLoads={setAssignableLoads}
                        setDeletableLoads={setDeletableLoads}
                        setBulkModifyRecords={setBulkModifyRecords}
                        setMergeRecords={setMergeRecords}
                        setCascadeRecords={setCascadeRecords}
                        setPublishRecords={setPublishRecords}
                    />
                </div>
                {!isDrafts && canCreateOrder && !currentUserIsCarrierOrg &&
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setDialogOpen(true)}
                    >
                        <Add />{L.createLoad()}
                    </Button>
                }
            </ContentHeader>
            <Loads2Modals
                isDrafts={isDrafts}
                table={table}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                editableLoad={editableLoad}
                setEditableLoad={setEditableLoad}
                delieryLogLoad={delieryLogLoad}
                setDeliveryLogLoad={setDeliveryLogLoad}
                unmergeLoad={unmergeLoad}
                setUnmergeLoad={setUnmergeLoad}
                completableLoads={completableLoads}
                setCompletableLoads={setCompletableLoads}
                assignableLoads={assignableLoads}
                carrierLoads={carrierLoads}
                setCarrierLoads={setCarrierLoads}
                setAssignableLoads={setAssignableLoads}
                deletableLoads={deletableLoads}
                setDeletableLoads={setDeletableLoads}
                bulkModifyRecords={bulkModifyRecords}
                setBulkModifyRecords={setBulkModifyRecords}
                mergeRecords={mergeRecords}
                setMergeRecords={setMergeRecords}
                cascadeRecords={cascadeRecords}
                setCascadeRecords={setCascadeRecords}
                publishRecords={publishRecords}
                setPublishRecords={setPublishRecords}
            />
            <Content style={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <Paper sx={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                    <LoadTableToolbar2
                        isDrafts={isDrafts}
                        isLoading={isLoading}
                        refetch={draftsLoadsQuery.refetch}
                        table={table}
                        resetTableState={resetTableState}
                    />
                    <LoadTable2
                        isDrafts={isDrafts}
                        isLoading={isLoading}
                        table={table}
                    />
                </Paper>
            </Content>
        </>
    );
};
