import React from 'react';
import { styled } from '@mui/material/styles';
import { InputLabel } from '@mui/material';
import { L } from 'harmony-language';
import { useTractorTrailer } from '../../api/queries/use-tractor-trailer';
import { CircularLoading } from './circular-loading';

const CompartmentDiagram1 = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1.3rem)',
    gridGap: '0.3rem 0.2rem',
    marginTop: '0.2rem',
    cursor: 'default',
});

interface CompartmentProps {
    hasTrailer: boolean;
    compartmentInUse: boolean;
    noCompartment: boolean | 0 | undefined;
    clickable: boolean;
}

const Compartment = styled('div', {
    shouldForwardProp: (prop) => prop !== 'hasTrailer' && prop !== 'compartmentInUse' && prop !== 'noCompartment' && prop !== 'clickable'
})<CompartmentProps>(({ hasTrailer, compartmentInUse, noCompartment, clickable, theme }) => ({
    padding: '2px',
    fontSize: '.75rem',
    textAlign: 'center',
    border: `1px solid ${theme.palette.grey[900]}`,
    borderRadius: '2px',
    height: '0.8rem',
    width: '0.8rem',
    ...(noCompartment && {
        opacity: .7,
        borderStyle: 'dotted'
    }),
    ...(compartmentInUse && {
        backgroundColor: theme.palette.grey[700],
        color: theme.palette.common.white
    }),
    ...(noCompartment && compartmentInUse && hasTrailer && {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        borderColor: theme.palette.error.dark,
        borderStyle: 'solid',
        opacity: 1
    }),
    ...(clickable && {
        cursor: 'pointer',
    }),
}));

/*
 no trailer
  - compartment not in use                      "No trailer selected - compartment unused in stop"
  - compartment in use                          "No trailer selected - compartment specified for use"

 has trailer
  - compartment not in use
     - compartment exists on trailer            "Not used on this stop"
     - compartment does not exist on trailer    "Does not exist on this trailer"
  - compartment in use
     - compartment exists on trailer            "Used on this stop"
     - compartment does not exist on trailer    "Specified for use on this stop but does not exist on this trailer"
*/

interface CompartmentDiagramProps {
    onChange?: ((compartmentNumber: number) => void);
    trailerId?: number | null;
    cargoTypeId?: number | null | undefined;
    compartments: number[];
    hideLabel?: boolean;
}

export const CompartmentDiagram: React.FC<CompartmentDiagramProps> = (props) => {
    const { onChange, trailerId, cargoTypeId, hideLabel = false, compartments = [] } = props;
    const { trailers, isLoadingTrailers } = useTractorTrailer();

    if (isLoadingTrailers) {
        return <CircularLoading />
    }

    const trailer = trailers?.find(x => x.id === trailerId);
    const trailerCargoType = trailer?.trailerCargoTypes?.find(x => x.cargoTypeId === cargoTypeId);
    const trailerCompartmentCount = Math.max(trailerCargoType?.compartmentCount || 0, ...compartments, 8);

    return (
        <>
            {!hideLabel && <InputLabel shrink={true}>{L.compartments()}</InputLabel>}
            <CompartmentDiagram1 data-testid='compartment-diagram'>
                {
                    // @ts-ignore
                    Array(trailerCompartmentCount).fill().map((_, i) => {
                        const compartmentNumber = i + 1;
                        const compartmentInUse = compartments.includes(compartmentNumber);
                        const hasTrailer = Boolean(trailerId);
                        const noCompartmentOnTrailer = (trailerCargoType?.compartmentCount && trailerCargoType.compartmentCount < compartmentNumber);
                        const noCompartment = !hasTrailer || noCompartmentOnTrailer;

                        return <Compartment key={i}
                            onClick={() => onChange ? onChange(compartmentNumber) : null}
                            hasTrailer={hasTrailer}
                            compartmentInUse={compartmentInUse}
                            noCompartment={noCompartment}
                            clickable={Boolean(onChange)}>
                            {compartmentNumber}
                        </Compartment>
                    })
                }
            </CompartmentDiagram1>
        </>
    );
};
