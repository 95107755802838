import { getTransCargoType } from '../../../../constants/constants';
import { localDate } from '../../../../utils/date-time-utils';
import {
    stopToLoadingTeamIdLabelTuple,
    stopToOrgLocationIdLabelTuple,
    toDriverDisplayName
} from '../../../../utils/data-mapping-utils';

export const doesValueMatch = (value, searchText) => {
    return value?.toString().toLowerCase().indexOf(searchText?.toLowerCase()) >= 0;
};

export const searchByStopDates = (value, searchText) => {
    return value.stops?.some((stop) => {
        return doesValueMatch(localDate(stop.arrivalTime), searchText) ||
            doesValueMatch(localDate(stop.readyTime), searchText) ||
            doesValueMatch(localDate(stop.requiredBegin), searchText) ||
            doesValueMatch(localDate(stop.requiredEnd), searchText);
    })
};

export const searchByStopCargo = (value, searchText) => {
    return value.stops?.some((stop) => {
        const cargoTypeLabel = getTransCargoType({ id: stop.cargoTypeId }).label;

        return doesValueMatch(cargoTypeLabel, searchText) || doesValueMatch(stop.productionPlan, searchText) || doesValueMatch(stop.lotId, searchText);
    });
};

export const searchByOrderNumber = (value, searchText) => {
    return value.stops?.some((stop) => {
        return doesValueMatch(stop.orderNumber, searchText);
    })
}

export const searchByStopLocation = (value, searchText, organizationLocations) => {
    const index = value.stops.map(stopToOrgLocationIdLabelTuple(organizationLocations)).findIndex(s => doesValueMatch(s.siteLabel, searchText));

    return index > -1;
};

export const searchByStopLoadingTeam = (value, searchText, loadingTeams) => {
    const index = value.stops.map(stopToLoadingTeamIdLabelTuple(loadingTeams)).findIndex(s => doesValueMatch(s.label, searchText));

    return index > -1;
};

export const searchByTractor = (value, searchText, tractors) => {
    const tractor = tractors?.find(t => t.id === value.tractorId)?.userDisplayName;

    return doesValueMatch(tractor, searchText);
};

export const searchByTrailer = (value, searchText, trailers) => {
    const trailer = trailers?.find(t => t.id === value.trailerId)?.userDisplayName;

    return doesValueMatch(trailer, searchText);
};

export const searchByDriver = (value, searchText, drivers) => {
    const driver = toDriverDisplayName(drivers)(value);

    return doesValueMatch(driver, searchText);
};

export const searchByStopCustomerOrderNumber = (value, searchText) => {
    return value.stops?.some((stop) => {
        return doesValueMatch(stop.customerOrderNumber, searchText);
    });
};

export const searchByLoadId = (load, searchText) => {
    return doesValueMatch(load.id, searchText);
};
