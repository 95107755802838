import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { useSnackbar } from '../../components/notifications/use-snackbar';
// import { dateRangeOverlaps } from '../../utils/date-time-utils';
import { Load } from '../../types';
import { useDraftsKey } from '../config-hooks';

interface MergeDraftsOptions {
    draft: Load;
    previousDrafts: Load[];
}

const mergeDrafts = async (options: MergeDraftsOptions) => {
    const draft = options.draft;
    const previousDrafts = options.previousDrafts;
    const draftIds = previousDrafts.map(x => x.id);

    return (await axios.post(`${API_BASE}/api/orders/merge?orderIds=${JSON.stringify(draftIds)}`, draft)).data;
}

const stringMessage = (previouseDrafts: Load[]) => {
    let message = '';
    previouseDrafts.map(d => {
        message += '#' + d.id + ' ';
    });
    return message.trim();
}

export const useMergeDraftsMutation = () => {
    const snackbar = useSnackbar();
    const draftsKey = useDraftsKey();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: mergeDrafts,
        // ...mutationOptions(draftsKey, queryClient),
        onMutate: () => {
            const previousCache = queryClient.getQueryData<Load[]>(draftsKey);

            return previousCache;
        },
        onError: (error, load, context) => {
            if (context) {
                queryClient.setQueryData<Load[]>(draftsKey, context);
            }
        },
        onSuccess: (data, options: { previousDrafts: Load[] }) => {
            const previousDrafts = options.previousDrafts;

            // I'm not 100% sure what this was doing, I think it's better that we just display a snackbar onSuccess and invalidate the cache onSettled
            // const filteredDrafts = previousCache.filter(draft => !previousDrafts.some(d => d.id === draft.id)) || [];
            // const mergedDraftWithinCacheDateRange = dateRangeOverlaps(data.date, previousCache?.timeWindow?.from, previousCache?.timeWindow?.to);
            // const newData = filteredDrafts;

            // if (mergedDraftWithinCacheDateRange) {
                // newData.push(data);
            // }
            // queryClient.setQueryData(draftsKey, {
            //     ...previousCache,
            //     data: newData.sortBy(u => u.id)
            // });
            snackbar(L.mergeDraftsSuccessMessage(stringMessage(previousDrafts), `#${data.id}`));
        },
        onSettled: () => {
            queryClient.invalidateQueries(draftsKey);
        },
    });

    return mutation;
};
