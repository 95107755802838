import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Load, Stop } from '../../../types';
import { deepEqual } from '../../../utils/deep-equal';
import { useEditLoadMutation } from '../../../api/mutations/edit/use-edit-load-mutation';
import { useDraftsKey, useLoadsKey } from '../../../api/config-hooks';
import { useDraftLoadContext } from './draft-load-context';
import { withThroughputs } from '../../../utils/throughput-utils';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';

interface DraftLoadStopContext {
    onEditedStopChanged: (value: Partial<Stop>) => void;
}

const DraftLoadStopContext = createContext<DraftLoadStopContext | undefined>(undefined);

interface DraftLoadStopProviderProps {
    inputStop: Stop;
    children: ReactNode;
}

export const DraftLoadStopProvider: FC<DraftLoadStopProviderProps> = (props) => {
    const { inputStop, children } = props;
    const { editedLoad, onEditedLoadChanged } = useDraftLoadContext();
    const { data: throughputs } = useOrganizationQuery(OrgQueryKeys.throughput);

    const onEditedStopChanged = (value: Partial<Stop>) => {
        const newLoad = {
            ...editedLoad,
            stops: editedLoad.stops.map(stop => {
                if (stop.sequence === inputStop.sequence) {
                    return {
                        ...stop,
                        ...value,
                    }
                } else {
                    return stop;
                }
            }).map(withThroughputs(throughputs)),
        }

        onEditedLoadChanged(newLoad);
    }

    return (
        <DraftLoadStopContext.Provider
            value={{
                onEditedStopChanged,
            }}
        >
            {children}
        </DraftLoadStopContext.Provider>
    );
}

export const useDraftLoadStopContext = () => {
    const draftLoadStopContext = useContext(DraftLoadStopContext);

    if (draftLoadStopContext === undefined) {
        throw new Error('useDraftLoadStopContext must be used within a DraftLoadStopProvider');
    }

    return draftLoadStopContext;
}
