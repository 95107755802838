import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { QueryKeys } from '../config';
import { useSnackbar } from '../../components/notifications/use-snackbar';
import { ORDER_STATUSES } from '../../constants/constants';
import { Load } from '../../types';
import { useLoadsKey } from '../config-hooks';
import { StopStatus } from 'harmony-constants';

interface RequestManuallyCompleteLoad {
    id: number;
    stops: {
        id: number;
        metadata: Record<string, any> | null;
        completedAt: string | null;
    }[];
}

const manuallyCompleteLoads = async (options: { loads: RequestManuallyCompleteLoad[] }) => {
    return (await axios.put(`${API_BASE}/api${QueryKeys.completeLoads}`, options.loads)).data;
}

export const useManuallyCompleteLoads = () => {
    const snackbar = useSnackbar();
    const loadsKey = useLoadsKey();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: manuallyCompleteLoads,
        // ...mutationOptions(loadsKey, queryClient),
        onMutate: async (options: { loads: RequestManuallyCompleteLoad[] }) => {
            const loads = options.loads;

            await queryClient.cancelQueries(loadsKey);
            const previousCache = queryClient.getQueryData<Load[]>(loadsKey);

            // Optimistically update to the new value
            if (previousCache) {
                const updatedLoads = previousCache.map(load => {
                    const match = loads.find(x => x.id === load.id);

                    if (match) {
                        const updateStops = load.stops.map(stop => {
                            const matchingStop = match.stops.find(x => x.id === stop.id);
                            if (matchingStop && stop.status !== StopStatus.Complete) {
                                return {
                                    ...stop,
                                    completedAt: matchingStop.completedAt,
                                    metadata: {
                                        manuallyCompleted: true,
                                    }
                                }
                            }
                            return stop;
                        });

                        return {
                            ...load,
                            status: ORDER_STATUSES().Delivered.key,
                            stops: updateStops,
                        }
                    }
                    return load;
                });

                queryClient.setQueryData(loadsKey, updatedLoads);
            }

            return previousCache;
        },
        onError: (error, load, context) => {
            if (context) {
                queryClient.setQueryData<Load[]>(loadsKey, context);
            }
        },
        onSuccess: (data) => {
            let message = '';

            if (data.length > 1) {
                message = L.manuallyCompleteSnackbarSuccess(`${data.length} ${L.loads().toLocaleLowerCase()}`);
            } else {
                message = L.manuallyCompleteSnackbarSuccess(L.load().toLocaleLowerCase());
            }
            snackbar(message);
        },
        onSettled: () => { }
    });

    return mutation;
};
