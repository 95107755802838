import { OrgQueryKeys, typedFetchWithTimeWindow } from '../../api/config';
import { Query, useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { add, getWorkingWeek, subtract, utcWithTz } from '../../utils/date-time-utils';
import { Booking, DateFilter } from '../../types';
import { useUser } from '../../api/queries/use-user';
import { useAgisticsContext } from '../../app/agistics-context';

export const useResourceBookings = () => {
    const { organizationId } = useUser()
    const bookingsKey = OrgQueryKeys.resolve(organizationId, OrgQueryKeys.bookings);
    const { pathname } = useLocation();
    const { dateFilters } = useAgisticsContext();
    let loadState = Object.values(dateFilters).find(x => x.page === pathname) as DateFilter;

    // TODO: temp until I find out if I need to keep old loads page working concurrently with new loads page
    if (!loadState) {
        const week0 = getWorkingWeek(0);
        loadState = {
            page: '/drafts',
            mode: 'week',
            offset: 0,
            from: week0.sundayIso,
            to: week0.saturdayIso,
            includeDrafts: true,
            includedDates: [],
        }
    }

    const adjustedDateFilter = React.useMemo(() => {
        return {
            // 1 day padding should suffice
            from: utcWithTz(subtract(1, 'days', loadState.from)),
            to: utcWithTz(add(1, 'days', loadState.to)),
        };
    }, []);

    // only interested in fetching the data when the from/to date changes or includeDrafts changes
    const refetchOnMountFunc = (query: Query<Booking, unknown, Booking, string[]>) => {
        if (loadState.from === query.options.meta?.from && loadState.to === query.options.meta?.to && loadState.includeDrafts === query.options.meta?.includeDrafts) {
            return false;
        } else {
            return 'always';
        }
    }

    const query = useQuery([bookingsKey], typedFetchWithTimeWindow<Booking>(adjustedDateFilter, { includeDrafts: loadState?.includeDrafts }), {
        enabled: Boolean(adjustedDateFilter.from && adjustedDateFilter.to),
        staleTime: 30 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: refetchOnMountFunc,
        meta: {
            from: loadState.from,
            to: loadState.to,
            includeDrafts: loadState.includeDrafts,
        },
    });

    return query;
};
