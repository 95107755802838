import { flexRender, Row, Table } from '@tanstack/react-table';
import React, { FC } from 'react';
import { Load } from '../../../types';
import { LoadTable2Stops } from './load-table2-stops';
import { L } from 'harmony-language';
import { gridTemplateColumnsGenerator } from './load-table2-header';
import { useUser } from '../../../api/queries/use-user';
import { DraftLoadProvider } from '../hooks/draft-load-context';

interface LoadTable2BodyProps {
    isDrafts: boolean;
    table: Table<Load>;
    showEstimatedTotalTripTime: boolean;
}

export const LoadTable2Body: React.FC<LoadTable2BodyProps> = (props) => {
    const { isDrafts, table, showEstimatedTotalTripTime } = props;

    const { user } = useUser();
    const draftsLoadsEditable = user.preferences.draftsLoadsEditable ?? true;

    return (
        <div className='lt-body'>
            {table.getRowModel().rows.length ? table.getRowModel().rows.map(row => {
                const isRowExpanded = row.getIsExpanded();
                const isRowEditable = draftsLoadsEditable || isRowExpanded;

                return (
                    <div key={row.id} className='lt-row-parent'>
                        <ConditionalLoadTableRow
                            isDrafts={isDrafts}
                            showEstimatedTotalTripTime={showEstimatedTotalTripTime}
                            isRowExpanded={isRowExpanded}
                            isRowEditable={isRowEditable}
                            row={row}
                        />
                    </div>
                );
            }) :
                <div style={{ display: 'flex', height: '10rem', justifyContent: 'center', alignItems: 'center' }}>
                    <span>{L.noRecords()}</span>
                </div>
            }
        </div>
    );
};

interface ConditionalLoadTableRowProps {
    isDrafts: boolean;
    showEstimatedTotalTripTime: boolean;
    isRowExpanded: boolean;
    isRowEditable: boolean;
    row: Row<Load>;
}

const ConditionalLoadTableRow: FC<ConditionalLoadTableRowProps> = (props) => {
    const { isDrafts, showEstimatedTotalTripTime, isRowExpanded, isRowEditable, row } = props;

    const rowContent = () => {
        return (
            <>
                <div
                    className='lt-row'
                    style={{ gridTemplateColumns: gridTemplateColumnsGenerator(isDrafts, showEstimatedTotalTripTime) }}
                >
                    {row.getVisibleCells().map(cell => (
                        <div
                            key={cell.id}
                            className='lt-cell lt-cell-sub'
                        >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </div>
                    ))}
                </div>
                {isRowExpanded && (
                    <LoadTable2Stops
                        isDrafts={isDrafts}
                        // load={row.original}
                    />
                )}
            </>
        );
    }

    if (isRowEditable) {
        return (
            <DraftLoadProvider
                isDrafts={isDrafts}
                inputLoad={row.original}
            >
                {rowContent()}
            </DraftLoadProvider>
        );
    }

    return rowContent();
}
