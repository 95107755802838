import { Button, MenuItem, Select, Typography } from '@mui/material';
import { L } from 'harmony-language';
import React from 'react';
import { ReactTableColumns, ReportRunOptions } from '../../../reports/report-types';
import { Table } from '@tanstack/react-table';
import { Load } from '../../../../types';

interface AgisticsTablePagingProps<T> {
    table: Table<T>;
    runReport?: (r: ReportRunOptions) => void;
    additionalCsvButton?: React.ReactNode;
    setPageSizePreferences?: (p: number) => void;
}

export const AgisticsTablePaging = <T extends ReactTableColumns | Load>(props: AgisticsTablePagingProps<T>) => {
    const { table, runReport, additionalCsvButton, setPageSizePreferences } = props;

    const numSelected = table.getSelectedRowModel().rows.length;

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '1rem' }}>
            <span>
                {L.totalRecords(table.getRowCount().toString())}&nbsp;-&nbsp;
                {L.displayedRecords(table.getRowModel().rows.length.toString())}
                {Boolean(numSelected) && (
                    <>&nbsp;-&nbsp;{L.xSelected(numSelected.toString())}</>
                )}
                {runReport && <Button
                    size='small'
                    variant='outlined'
                    style={{ marginLeft: '10px' }}
                    onClick={() => runReport({ csv: true })}
                    disabled={!table.getRowCount()}
                >
                    CSV
                </Button>}
                {additionalCsvButton}
            </span>
            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Typography style={{ marginRight: '10px' }}>{L.rowsPerPage()}</Typography>
                <Select
                    variant='standard'
                    disableUnderline
                    defaultValue={50}
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        const pageSize = Number(e.target.value);
                        table.setPageSize(pageSize);
                        setPageSizePreferences && setPageSizePreferences(pageSize);
                    }}>
                    {[5, 10, 25, 50, 100].map(pageSize => (
                        <MenuItem key={pageSize} value={pageSize}>{pageSize}</MenuItem>
                    ))}
                </Select>
                <span className='' style={{ marginLeft: '10px', marginRight: '10px' }}>
                    <span>{L.page()} {' '}</span>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} of{' '}
                        {table.getPageCount().toLocaleString()}
                    </strong>
                </span>
                <Button
                    size='small'
                    variant='outlined'
                    onClick={() => table.firstPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    {'<<'}
                </Button>
                <Button
                    size='small'
                    variant='outlined'
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    {'<'}
                </Button>
                <Button
                    size='small'
                    variant='outlined'
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    {'>'}
                </Button>
                <Button
                    size='small'
                    variant='outlined'
                    onClick={() => table.lastPage()}
                    disabled={!table.getCanNextPage()}
                >
                    {'>>'}
                </Button>
                {/* works, but not sure we need?
                <span className=''>
                    | Go to page:
                        <input
                            type='number'
                            defaultValue={table.getState().pagination.pageIndex + 1}
                            onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            table.setPageIndex(page)
                            }}
                            className=''
                        />
                </span> */}
            </span>
        </div>
    );
};
