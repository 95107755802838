import React from 'react';
import { styled } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'
import { SelectLanguage } from '../shared/inputs/select-language';
import { SelectTimezone } from '../shared/inputs/select-timezone';
import { getTransUserRole } from '../../constants/constants';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { getCurrentLanguage, getCurrentUnitSystem, L, setCurrentUnitSystem } from 'harmony-language';
import { getTimezone, setTimezone } from '../../utils/date-time-utils';
import { SelectUnits } from '../shared/inputs/select-units';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useBoolean } from '../shared/hooks/use-boolean';
import { CircularLoading } from '../shared/circular-loading';
import { getRoles } from './utils/user-mappings';
import { Link } from 'react-router-dom';
import { UserNotificationPreferences } from './user-notification-preferences';
import { useCarrier } from './selectors/use-permissions';
import { SelectUnitsWeight } from '../shared/inputs/select-units-weight';
import { useUser } from '../../api/queries/use-user';
import { userSignOut } from './actions/user-sign-out';
import { useEditContactPreferences } from '../../api/mutations/edit/use-edit-contact-preferences-mutation';
import { fetchLanguageDictionary } from './actions/fetch-language-dictionary';
import { useQueryClient } from '@tanstack/react-query';
import { UserResourcePreferences } from './user-resource-preferences';
import { FormControlLabel, Switch } from '@mui/material';
import { useUpdateUserPreferences } from '../shared/hooks/use-update-user-preferences';

const Header = styled('div')({
    paddingBottom: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const Roles = styled('div')({
    paddingBottom: '0.5rem',
})

const UserSettings = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const UserButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.secondary.light,
    transition: '0.3s',
    '&:hover': {
        color: theme.palette.secondary.contrastText
    }
}));

const UserSettingsPopper = styled(Popper)(({ theme }) => ({
    left: `${theme.spacing(2)} !important`,
    zIndex: 50
}));

const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    transition: '0.3s',
    color: theme.palette.secondary.light,
    '&:hover': {
        color: theme.palette.secondary.dark,
    },
}));

export const UserInformation = () => {
    const { user } = useUser();
    const [anchorElement, setAnchorElement] = React.useState(null);
    const [isNotificationPrefsOpen, setNotificationPrefsOpen, setNotificationPrefsClosed] = useBoolean(false);
    const [isResourcePrefsOpen, setResourcePrefsOpen, setResourcePrefsClosed] = useBoolean(false);
    const [isLoading, loading, done] = useBoolean(false);
    const currentUserIsCarrier = useCarrier();
    const { mutate: mutateContactPreferences } = useEditContactPreferences(user.organizationId, user.contact.id);
    const queryClient = useQueryClient();
    const { updatePreferences } = useUpdateUserPreferences();

    const draftsLoadsEditable = user.preferences.draftsLoadsEditable ?? true;

    const updateContactPreferences = (preference) => {
        const contact = user.contact;
        const newContact = {
            ...contact,
            ...preference,
        }
        mutateContactPreferences(newContact);
    }

    const languageChanged = async (value) => {
        loading();
        updateContactPreferences({ language: value });
        fetchLanguageDictionary(value);
        done();
    };

    const timezoneChanged = async (value) => {
        loading();
        updateContactPreferences({ timezone: value });
        setTimezone(value);
        done();
    };

    const unitsChanged = async (value) => {
        loading();
        updateContactPreferences({ units: value });
        setCurrentUnitSystem(value);
        done();
    };

    const unitsWeightChanged = async (value) => {
        loading();
        updateContactPreferences({ unitsWeight: value });
        done();
    };

    const handleSwitchChange = (key, currentVal) => {
        const newPreferences = {
            ...user.preferences,
            [key]: !currentVal,
        }
        updatePreferences(newPreferences);
    }

    // TODO
    const showEstimatedTotalTripTimeChanged = () => {
        const newPreferences = {
            ...user.preferences,
            showEstimatedTotalTripTime: !user.preferences.showEstimatedTotalTripTime,
        }
        updatePreferences(newPreferences);
    };

    return (
        <>
            {isNotificationPrefsOpen && <UserNotificationPreferences contactId={user.contactId} organizationId={user.organizationId} isOpen={isNotificationPrefsOpen} closePreferences={setNotificationPrefsClosed} />}
            {isResourcePrefsOpen && <UserResourcePreferences organizationId={999} isOpen={isResourcePrefsOpen} closePreferences={setResourcePrefsClosed} /> }
            <ClickAwayListener
                mouseEvent='onMouseDown'
                touchEvent='onTouchStart'
                onClickAway={() => setAnchorElement(null)}
            >
                <div>
                    <UserButton
                        onClick={(e) => setAnchorElement(anchorElement ? null : e.currentTarget)}
                        aria-label='User Settings'
                        size='large'>
                        <AccountCircle fontSize='large' />
                    </UserButton>
                    <UserSettingsPopper open={Boolean(anchorElement)} anchorEl={anchorElement}
                        placement='right-end'>
                        <UserSettings variant='outlined' data-testid='user-settings'>
                            <Header>
                                <Typography variant='h6'>{user.name}</Typography>
                                <StyledLink to='/login' onClick={() => userSignOut(queryClient)}>{L.signOut()}</StyledLink>
                            </Header>
                            <Roles>{user.organization.name}</Roles>
                            <Roles>{getRoles(user).map(role => getTransUserRole(role).label).join(', ')}</Roles>
                            <SelectLanguage onChange={languageChanged} language={getCurrentLanguage()}
                                disabled={isLoading} />
                            <SelectTimezone onChange={timezoneChanged} timezone={getTimezone()}
                                disabled={isLoading} />
                            <SelectUnits onChange={unitsChanged} units={getCurrentUnitSystem()}
                                disabled={isLoading} />
                            <SelectUnitsWeight onChange={unitsWeightChanged} unitsWeight={user.contact.unitsWeight} 
                                disabled={isLoading} />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={draftsLoadsEditable}
                                        onChange={() => handleSwitchChange('draftsLoadsEditable', draftsLoadsEditable)}
                                    />
                                }
                                label={L.draftsLoadsEditable()}
                            />
                            <br />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={user.preferences.showEstimatedTotalTripTime || false}
                                        onChange={showEstimatedTotalTripTimeChanged}
                                    />
                                }
                                label={L.showEstimated()}
                            />
                            {isLoading && <CircularLoading />}
                            <div>
                                {!currentUserIsCarrier ? <Button variant='contained' style={{ marginTop: '1rem', marginRight: '8px' }} onClick={() => {
                                    setAnchorElement(null);
                                    setNotificationPrefsOpen();
                                }}>{L.notificationPreferences()}</Button> : null}

                                <Button variant='contained' style={{ marginTop: '1rem' }} onClick={() => {
                                    setAnchorElement(null);
                                    setResourcePrefsOpen();
                                }}>{L.resourcePreferences()}</Button>
                            </div>
                        </UserSettings>
                    </UserSettingsPopper>
                </div>
            </ClickAwayListener>
        </>
    );
};
