import React from 'react';
import { AgisticsMultiItem } from './agistics-multi-select';
import { IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface AgisticsMultiSelectChildrenProps<T> {
    selectedValues: AgisticsMultiItem<T>[];
    option: AgisticsMultiItem<T>;
    setSelectedIds: (s: T[], a?: T) => void;
    children: AgisticsMultiItem<T>[];
}

export const AgisticsMultiSelectChildren = <T extends string | number>(props: AgisticsMultiSelectChildrenProps<T>) => {
    const { selectedValues, option, setSelectedIds, children } = props;

    return (
        <div
            onClick={(e) => { e.preventDefault() }}
            style={{ width: '100%' }}
        >
            {children.map(x => {
                return (
                    <div key={x.id}>
                        <IconButton
                            size='small'
                            onClick={() => {
                                const newSelectedIds = [...new Set([
                                    ...selectedValues.map(x => x.id),
                                    option.id,
                                ])];
                                setSelectedIds(newSelectedIds, x.id);
                            }}
                        >
                            <ChevronRightIcon fontSize='inherit' />
                        </IconButton>
                        {x.value}
                    </div>
                );
            })}
        </div>
    );
};
