import { getCurrentLanguage, L, TIMEZONES } from 'harmony-language';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { padZero } from './string-utils';

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

Date.prototype.stdTimezoneOffset = function () {
    const jan = new Date(this.getFullYear(), 0, 1);
    const jul = new Date(this.getFullYear(), 6, 1);

    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Date.prototype.isDstObserved = function () {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
};

export const DatePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]);

export const getMinMax = (...args) => {
    const filteredArgs = args.filter(Boolean);

    if (filteredArgs.length === 0) {
        return {
            min: null,
            max: null
        };
    }

    const min = Math.min.apply(null, filteredArgs.map(d => moment.tz(d, getTimezone())));
    const max = Math.max.apply(null, filteredArgs.map(d => moment.tz(d, getTimezone())));

    return {
        min: utc(min),
        max: utc(max)
    };
};

// Based on the sourceWindow, come up with a new window
export const getMissingTimeWindow = (sourceWindow, compareWindow) => {
    const isValidSource = Boolean(sourceWindow?.to) && Boolean(sourceWindow?.from) && isBefore(sourceWindow.from, sourceWindow.to);
    const isValidCompare = Boolean(compareWindow?.to) && Boolean(compareWindow?.from) && isBefore(compareWindow.from, compareWindow.to);

    if (!isValidCompare) {
        return null;
    } else if (!isValidSource) {
        return {
            from: utcWithTz(compareWindow.from),
            to: utcWithTz(compareWindow.to),
        };
    }

    // Case where compare completely overlaps
    if (isBefore(compareWindow.from, sourceWindow.from) && isAfter(compareWindow.to, sourceWindow.to)) {
        return {
            from: utcWithTz(compareWindow.from),
            to: utcWithTz(compareWindow.to),
        };
    }

    if (isAfter(compareWindow.to, sourceWindow.to)) {
        return {
            from: utcWithTz(sourceWindow.to),
            to: utcWithTz(compareWindow.to),
        };
    }

    if (isBefore(compareWindow.from, sourceWindow.from)) {
        return {
            from: utcWithTz(compareWindow.from),
            to: utcWithTz(sourceWindow.from),
        };
    }

    return null;
};

export const getTimeDifference = (fromTime, toTime) => {
    const timeDifference = moment(toTime).diff(moment(fromTime));

    return getDuration(timeDifference);
};

export const getDifference = (fromDate, toDate, unit) => {
    const a = moment.tz(fromDate, getTimezone()).startOf(unit);
    const b = moment.tz(toDate, getTimezone()).startOf(unit);

    return a.diff(b, unit);
};

export const getDuration = (timeDifferenceInMilliseconds) => {
    if (!timeDifferenceInMilliseconds) {
        return '0m';
    }

    let duration = timeDifferenceInMilliseconds;
    const durationElements = [];

    if (duration / DAY >= 1) {
        const days = Math.floor(duration / DAY);

        durationElements.push(`${days}d`);
        duration -= days * DAY;
    }
    if (duration / HOUR >= 1) {
        const hours = Math.floor(duration / HOUR);

        durationElements.push(`${hours}h`);
        duration -= hours * HOUR;
    }
    if (duration / MINUTE >= 1) {
        const minutes = Math.floor(duration / MINUTE);

        durationElements.push(`${minutes}m`);
    }

    return durationElements.join(' ');
};

// export const dateRangeOverlaps = (date, fromDate, toDate) => {
//     return moment(date).isBetween(moment(fromDate), moment(toDate), 'day', '[]'); // '[' indicates inclusion, '(' indicates exclusion
// };

export const isSame = (a, b, unit) => {
    return moment.tz(a, getTimezone()).isSame(moment.tz(b, getTimezone()), unit);
};

export const isBefore = (a, b) => {
    return moment(a).isBefore(moment(b));
};

export const isSameOrBefore = (a, b) => {
    return moment(a).isSameOrBefore(moment(b));
};

export const isAfter = (a, b) => {
    return moment(a).isAfter(moment(b));
};

export const isValidDate = (date) => {
    if (!date) {
        return false;
    }

    return moment(date).isValid();
};

export const getWorkingWeekFromDate = (date) => {
    const localDate = local(date);

    return workingWeek(localDate);
};

export const getWorkingWeek = (weekOffset) => {
    const now = moment.tz(new Date(), timezone).add(weekOffset, 'week');
    return workingWeek(now);
};

const workingWeek = (day) => {
    const sundayValue = day.startOf('week');
    const sunday = localDateShort(sundayValue);
    const saturdayValue = moment(sundayValue).add(6, 'day');
    const saturday = localDateShort(saturdayValue);
    // United States standard of January 1st; could vary based on locale
    const weekNumber = day.week();

    return {
        today: day.toISOString(), // this actually gets Sunday of the working week because of the .startOf mutation...
        weekNumber,
        sunday,
        sundayValue,
        sundayIso: moment(sundayValue).format('YYYY-MM-DD'),
        saturday,
        saturdayValue,
        saturdayIso: moment(saturdayValue).format('YYYY-MM-DD'),
        label: `${L.weekNumber(weekNumber)} (${sunday} - ${saturday})`
    };
};

export const getWorkingWeeks = (numOfWeeks = 1) => {
    return [...Array(numOfWeeks)]
        .map((x, i) => {
            return getWorkingWeek(i);
        });
};

export const getDaysOfWorkingWeek = (sunday) => {
    return [...Array(7)]
        .map((x, i) => ({
            label: moment(sunday).add(i, 'day').format('ddd MMM DD'),
            value: moment(sunday).add(i, 'day')
        }));
};

export const getLastWorkingWeek = () => {
    const thisSunday = moment().startOf('week');

    return [...Array(7)]
        .map((x, i) => ({ label: thisSunday.add(-(7 - i), 'day').format('YYYY-MM-DD') }));
};

export const utc = (date) => {
    return moment(date).utc().toISOString();
};

export const utcWithTz = (date) => {
    return moment.tz(date, getTimezone()).utc().toISOString();
};

export const day = (date) => {
    return moment(date).day();
};

export const subtract = (amount, unit, date) => {
    return moment(date).subtract(amount, unit);
};

export const add = (amount, unit, date) => {
    return moment(date).add(amount, unit);
};

export const endOfDay = (date) => {
    return moment.tz(date, getTimezone()).endOf('day');
};

export const startOfDay = (date) => {
    return moment.tz(date, getTimezone()).startOf('day');
};

let timezone = TIMEZONES.find(x => x === Intl.DateTimeFormat()?.resolvedOptions()?.timeZone) || 'America/Chicago';

export const setTimezone = (updatedTimezone) => {
    timezone = updatedTimezone;
    moment.tz.setDefault(updatedTimezone);
};

export const getTimezone = () => timezone;

export const localNow = () => {
    return moment.tz(new Date(), timezone);
};

export const local = (date) => {
    return moment.tz(date, timezone);
};

export const localTime = (date) => {
    const languageCode = getCurrentLanguage();

    return new Date(moment.tz(date, timezone).format('YYYY-MM-DD HH:mm:ss')).toLocaleTimeString(languageCode, { timeStyle: 'short' });
};

export const is12HourFormat = () => {
    const languageCode = getCurrentLanguage();
    const hourComponent = new Date(new Date().setHours(13)).toLocaleTimeString(languageCode, { hours: 'short' }).substring(0, 2);

    return hourComponent === '1:' || hourComponent === '01';
};

export const localDate = (date) => {
    return moment.tz(date, timezone).format('YYYY-MM-DD');
};

export const localDateShort = (date, options = {}) => {
    const languageCode = getCurrentLanguage();

    return new Date(moment.tz(date, timezone).format('YYYY-MM-DD HH:mm:ss')).toLocaleDateString(languageCode, {
        month: 'short',
        day: 'numeric',
        ...options
    });

};

// an attempt to display date/time strings consistently throughout Agistics
export const localDateTimeDisplay = (dateString)  => {
    return moment.tz(dateString, timezone).format('YYYY-MM-DD hh:mm a');
}

//used for downtime where the date string will be shown with a time zone
export const unlocalizedDateTimeDisplay = (dateString) => {
    console.log("dateString??", dateString);
    return moment(dateString).format('YYYY-MM-DD hh:mm a');
}

export const durationToHoursMinutes = (durationMinutes) => {
    if (durationMinutes < 60) {
        return `${durationMinutes} ${L.mins()}`;
    }
    return `${parseInt(durationMinutes / 60)} ${L.hrs()} ${durationMinutes % 60} ${L.mins()}`
}

export const getDowntimeEndDateString = (downtime) => {
    if (!downtime)
        return null;
    console.log(`${downtime.startDate} ${downtime.startTime}`, downtime.timezone, downtime.durationMinutes)
    return moment(`${downtime.startDate} ${downtime.startTime}`)
                .add(downtime.durationMinutes, 'minutes')
                .format('YYYY-MM-DD HH:mm')
}

function formatYearMonthDay(date) {
    const d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    return [year, padZero(month, 2), padZero(day, 2)].join('-');
}

function formatHourMinuteSecond(date) {
    const d = new Date(date),
        hours = '' + d.getHours(),
        minutes = '' + d.getMinutes(),
        seconds = d.getSeconds();

    return [padZero(hours, 2), padZero(minutes, 2), padZero(seconds, 2)].join(':');
}

/**
Take a datetime input created by system/browser and trade its timezone for the preferred timezone of user/company
Example:
    - preferred timezone: Australia/Brisbane
    - system timezone: America/Chicago (CDT) - 15 hours behind Australia/Brisbane
    - current system time: '07-14-2020 12:00 pm CDT'
    - converts to: '07-14-2020 12:00 pm (Australia/Brisbane)'
 */
export const replaceTimeZone = (date) => {
    const dateTimeString = `${formatYearMonthDay(date)} ${formatHourMinuteSecond(date)}`; // formats as 'YYYY-MM-DD HH:mm:ss'

    return moment.tz(dateTimeString, timezone);
};

function normalizeDate(date) {
    if (date?.toDate) {
        return date.toDate().getTime();
    }
    return new Date(date || null).getTime();
}
export const checkIfDateRangesOverlap = (dateRange1, dateRange2) => {
    const range1begin = normalizeDate(dateRange1.begin);
    const range1end = normalizeDate(dateRange1.end);
    const range2begin = normalizeDate(dateRange2.begin);
    const range2end = normalizeDate(dateRange2.end);

    const hasSameOrBefore = (range1end <= range2begin || range2end <= range1begin);
    if (!hasSameOrBefore) {
        return true;
    }

    // special case where if both date ranges are the same, they should be considered overlapping
    if ((range1begin === range1end) && (range1end === range2begin) && (range2begin === range2end)) {
        return true;
    }

    return false;
}