import React, { useMemo } from 'react';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { OrganizationLocation, Stop } from '../../../../types';
import { OrgQueryKeys } from '../../../../api/config';
import { toSiteDisplayNameFromOrganizationLocation } from '../../../../utils/data-mapping';
import { useDraftLoadStopContext } from '../../hooks/draft-load-stop-context';
import { AgisticsSelect } from '../../../shared/agistics-select';

interface EditableStopLocationProps {
    stop: Stop;
}

export const EditableStopLocation: React.FC<EditableStopLocationProps> = (props) => {
    const { stop } = props;
    const { onEditedStopChanged } = useDraftLoadStopContext();
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);

    const organizationLocationMultiItems = useMemo(() => {
        return organizationLocations.map(organizationLocation => ({
            id: organizationLocation.id,
            value: toSiteDisplayNameFromOrganizationLocation(organizationLocation),
        }));
    }, [organizationLocations]);

    return (
        <AgisticsSelect
            item={stop.organizationLocationId}
            items={organizationLocationMultiItems}
            onChange={(organizationLocationId) => {
                onEditedStopChanged({ organizationLocationId: organizationLocationId });
            }}
        />
    );
};
