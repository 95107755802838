import { Tooltip } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { getTransCargoTypeLabel, STOP_STATUSES, STOP_TYPES } from '../../../constants/constants';
import { OrganizationLocation, Stop } from '../../../types';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { DynamicQueryKeys, OrgQueryKeys } from '../../../api/config';
import { toSiteDisplayName } from '../../../utils/data-mapping';
import { localDateTimeDisplay } from '../../../utils/date-time-utils';
import { useWeights } from '../../shared/hooks/use-weights';
import { StopNotesModal } from '../../shared/load-table/stop-notes-modal';
import { ImagesModal } from '../../shared/images-modal';
import { L } from 'harmony-language';
import { CompartmentDiagram } from '../../shared/compartment-diagram';

interface SharedStopProps {
    stop: Stop;
}

export const StopType2: FC<SharedStopProps> = (props) => {
    const { stop } = props;

    return (
        <Tooltip
            title={<span style={{ fontSize: '.875rem' }}>{STOP_STATUSES()[stop.status].label}</span>}
            arrow
            placement='right'>
                <span style={{ color: STOP_STATUSES()[stop.status].color, fontWeight: 'bold' }}>{STOP_TYPES()[stop.type].label}</span>
        </Tooltip>
    );
}

export const StopLocation2: FC<SharedStopProps> = (props) => {
    const { stop } = props;
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);

    const stopLocationName = useMemo(() => {
        const organizationLocation = organizationLocations.find(x => x.id === stop.organizationLocationId);
        if (organizationLocation) {
            const sublocationNames = organizationLocation?.orgSubLocations?.filter(x => stop.organizationSubLocationIds?.includes(x.id)).map(x => x.name).join(', ');
            return toSiteDisplayName(organizationLocation.name, organizationLocation.description, sublocationNames);
        }
    }, [stop]);

    return (
        <span title={stopLocationName}>{stopLocationName}</span>
    );
}

interface StopTime2Props {
    dateTime: string | null;
}

export const StopTime2: FC<StopTime2Props> = (props) => {
    const { dateTime } = props;

    if (dateTime) {
        return localDateTimeDisplay(dateTime);
    }
}

interface StopQuantity2Props {
    quantity: number | null;
}

export const StopQuantity2: FC<StopQuantity2Props> = (props) => {
    const { quantity } = props;

    return quantity;
}

interface StopWeight2Props {
    weight: number | null;
}

export const StopWeight2: FC<StopWeight2Props> = (props) => {
    const { weight } = props;
    const { convertFromGramsDisplay } = useWeights();

    if (weight) {
        return convertFromGramsDisplay(weight);
    }
}

export const StopCargoType2: FC<SharedStopProps> = (props) => {
    const { stop } = props;

    if (stop.cargoTypeId) {
        return getTransCargoTypeLabel(stop.cargoTypeId);
    }
}

interface StopText2Props {
    text: string | null;
}

export const StopText2: FC<StopText2Props> = (props) => {
    const { text } = props;

    return text;
}

export const StopNotes2: FC<SharedStopProps> = (props) => {
    const { stop } = props;

    const key = DynamicQueryKeys.stopImages(stop.id);

    return (
        <div>
            <StopNotesModal stop={stop} stopEditDisabled={false} />
            {stop.imageIds?.length &&
                <ImagesModal imageLabel={L.stopPhoto()} modalLabel={L.stopPhotos()} queryKey={key} />
            }
        </div>
    )
}

interface StopCompartments2Props {
    stop: Stop;
    trailerId: number | null;
}

export const StopCompartments2: FC<StopCompartments2Props> = (props) => {
    const { stop, trailerId } = props

    return (
        <CompartmentDiagram
            // onChange={onChange ? compartmentChanged : undefined}
            trailerId={trailerId}
            cargoTypeId={stop.cargoTypeId}
            compartments={stop.compartments || []}
            hideLabel={true}
        />
    );
}
