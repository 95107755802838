import { Button } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { L } from 'harmony-language';
import React, { useState } from 'react';
import { Load, Preset2 } from '../../../types';
import { anyColumnFiltered } from '../utils';
import { useUpdateUserPreferences } from '../../shared/hooks/use-update-user-preferences';
import { ModalDialog } from '../../shared/containers/modal-dialog';
import { PresetsModal } from './presets-modal';
import { Select } from '../../shared/inputs/select';
import { SnackbarTypes, useSnackbar } from '../../notifications/use-snackbar';

const applyPresetToTable = (table: Table<Load>, preset: Preset2) => {
    table.resetColumnFilters(true);
    for (const filter of preset.filters) {
        const column = table.getColumn(filter.field);
        if (column) {
            column.setFilterValue(filter.value);
        }
    }
}

interface Loads2PresetsProps {
    table: Table<Load>;
}

// there are some MUI warnings in here, when setItem is called to keep state
// going to wait until I get feedback on this before I fix, still not sure I have it working properly
export const Loads2Presets: React.FC<Loads2PresetsProps> = (props) => {
    const { table } = props;
    const [nameDialogOpen, setNameDialogOpen] = useState(false);
    const { preferences, updatePreferences } = useUpdateUserPreferences();
    const snackbar = useSnackbar();

    const defaultPreset = preferences?.presets2?.find(x => x.default);
    const [item, setItem] = useState({
        id: defaultPreset?.name,
        label: defaultPreset?.name,
    });

    const anyColFiltered = anyColumnFiltered(table);

    const handlePresetSave = (name: string) => {
        if (preferences.presets2?.find(x => x.name.toLocaleLowerCase() === name.toLocaleLowerCase())) {
            snackbar(L.presetNameError(), SnackbarTypes.error);
        } else {
            const getAllColumns = table.getAllColumns();
            const filters = getAllColumns.map(column => {
                const filterValue = column.getFilterValue();
                if (filterValue) {
                    const newFilter = {
                        field: column.id,
                        value: filterValue,
                    }

                    return newFilter;
                }
            }).filter(x => !!x);

            const newPreferences = {
                ...preferences,
                presets2: [
                    ...(preferences.presets2 ? preferences.presets2 : []),
                    {
                        name: name,
                        // default: Boolean(defaultPreset) ? false : true,
                        default: false,
                        filters: filters,
                    }
                ]
            }

            updatePreferences(newPreferences);
            setNameDialogOpen(false);
            setItem(() => {
                return {
                    id: name,
                    label: name,
                }
            });
        }
    }

    const handlePresetApply = () => {
        if (preferences.presets2) {
            const selectedPreset = preferences.presets2.find(x => x.name === item.id);
            if (selectedPreset) {
                applyPresetToTable(table, selectedPreset);
            }
        }
    }

    const handlePresetDefault = () => {
        const oldPresets2 = preferences.presets2;
        if (oldPresets2) {
            const foundDefaultPreset = oldPresets2.find(x => x.name === item.id);
            if (foundDefaultPreset) {

                const otherPresets = oldPresets2.map(x => {
                    if (x.name !== item.id) {
                        return {
                            ...x,
                            default: false,
                        }
                    }
                }).filter(x => !!x);

                const newDefaultPreset = {
                    ...foundDefaultPreset,
                    default: true,
                }

                const newPreferences = {
                    ...preferences,
                    presets2: [
                        // default always at top?
                        newDefaultPreset,
                        ...otherPresets,
                    ]
                }

                updatePreferences(newPreferences);
            }
        }
    }

    const handlePresetDelete = () => {
        const oldPresets2 = preferences.presets2;
        if (oldPresets2) {
            const newPresets = oldPresets2.filter(x => x.name !== item.id);
            const newPreferences = {
                ...preferences,
                presets2: newPresets,
            }

            updatePreferences(newPreferences);

            if (newPresets.length) {
                const preset0 = newPresets[0];
                applyPresetToTable(table, preset0);
                setItem({
                    id: newPresets[0].name,
                    label: newPresets[0].name,
                });
            }
        }
    }

    const list = preferences.presets2?.map(preset => {
        return {
            id: preset.name,
            label: preset.name,
        };
    }) || [];

    return (
        <div
            style={{
                display: 'flex',
                columnGap: '15px',
                alignItems: 'flex-end',
                minWidth: '40rem',
                justifyContent: 'flex-end',
            }}
        >
            <ModalDialog
                title={L.nameThisPreset()}
                open={nameDialogOpen}
                onClose={() => {
                    setNameDialogOpen(false)
                }}
            >
                <PresetsModal handlePresetSave={handlePresetSave} />
            </ModalDialog>
            {Boolean(list.length) && <>
                <Select
                    label={L.presets()}
                    list={list}
                    item={item}
                    style={{ width: '15rem' }}
                    onChange={(item) => {
                        setItem(item);
                        if (preferences.presets2) {
                            const selectedPreset = preferences.presets2.find(x => x.name === item.id);
                            if (selectedPreset) {
                                applyPresetToTable(table, selectedPreset);
                            }
                        }
                    }}
                />
                <Button
                    variant='contained'
                    onClick={handlePresetApply}
                >
                    {L.apply()}
                </Button>
                <Button
                    variant='contained'
                    color='secondary'
                    disabled={defaultPreset?.name === item.id}
                    onClick={handlePresetDefault}
                >
                    {L.default()}
                </Button>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={handlePresetDelete}
                >
                    {L.delete()}
                </Button>
            </>}
            <Button
                sx={{ minWidth: '8rem' }}
                variant='contained'
                disabled={!anyColFiltered}
                onClick={() => setNameDialogOpen(true)}
            >
                {L.newPreset()}
            </Button>
        </div>
    );
};
