import { Table } from '@tanstack/react-table';
import { Load, User } from '../../types';
import { GlobalFilterValue } from './table-components/filters/use-loads-global-filter';

export const anyColumnFiltered = (table: Table<Load>) => {
    return table.getAllColumns().map(x => x.getIsFiltered()).some(x => !!x);
}

export const getPageSize = (isDrafts: boolean, user: User) => {
    const preferencesKey = isDrafts ? 'drafts' : 'loads';
    const pageSize = user.preferences?.tables?.[preferencesKey]?.pageSize || 100;
    return pageSize;
}

export const globalFilterRemover = (currentGlobalFilter: any, field: keyof GlobalFilterValue) => {
    const newFilter: GlobalFilterValue = { ...currentGlobalFilter };
    delete newFilter[field];
    const length = Object.keys(newFilter).length;
    return length ? newFilter : undefined;
}

// grid-template-columns: 1.5fr 3.5fr 1.5fr 2.5fr 2.5fr 2.5fr 1.25fr 1.25fr 1.25fr 1.25fr 2fr 1.75fr 1.75fr 1.75fr 60px .5fr;
export const stopGridTemplateColumnsGenerator = (isDrafts: boolean) => {
    const gridTemplateColumns = [
        '1.5fr',
        '3.5fr',
        '1.5fr',
        '2.5fr',
        '2.5fr',
        // ...(isDrafts ? [] : ['1fr']),
        ...(isDrafts ? [] : ['2.5fr']),
        '1.25fr',
        ...(isDrafts ? [] : ['1.25fr']),
        '1.25fr',
        ...(isDrafts ? [] : ['1.25fr']),
        '2fr',
        '1.75fr',
        '1.75fr',
        '1.75fr',
        '60px',
        '.5fr',
    ];

    return gridTemplateColumns.join(' ');
}
