import React from 'react';
import { Stop } from '../../../../types';
import { useDraftLoadStopContext } from '../../hooks/draft-load-stop-context';
import { TextField } from '@mui/material';
import { isCargoStop } from '../../../../constants/constants';
import { editableStopInputProps } from '../../table-components/load-table2-stop-row';

interface EditableStopTextProps {
    stop: Stop;
    property: 'orderNumber' | 'productionPlan' | 'lotId' | 'customerOrderNumber';
}

export const EditableStopText: React.FC<EditableStopTextProps> = (props) => {
    const { stop, property } = props;
    const { onEditedStopChanged } = useDraftLoadStopContext();
    const text = stop[property];

    if (!isCargoStop(stop)) {
        return null;
    }

    return (
        <TextField
            variant='standard'
            value={text || ''}
            inputProps={{ ...editableStopInputProps }}
            // disabled={isSaving || stopEditDisabled}
            fullWidth
            onChange={(e) => {
                onEditedStopChanged({ [property]: e.target.value });
            }}
        />
    );
};
